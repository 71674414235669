import React, {
  useContext,
  createContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useNetInfo } from '@react-native-community/netinfo';
import { Tracer } from '@oolio-group/tracer-client';
import { useTimeout } from '../useTimeout';
import { fetchWithTimeout } from '../../utils/fetchUtil';
import { netWorkStatus } from './useNetworkStatusVar';

interface NetworkStatusContextType {
  isConnected: boolean;
  isLoading: boolean;
  performReachabilityTest: () => void;
}

interface NetworkProviderProps {
  children: React.ReactNode;
}

const NetworkContext = createContext<NetworkStatusContextType | null>(null);

export const NetworkStatusProvider: React.FC<NetworkProviderProps> = ({
  children,
}: NetworkProviderProps) => {
  const netInfo = useNetInfo();
  const [isConnected, setIsConnected] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const setOnlineWithDelay = useTimeout(() => setIsConnected(true));

  const setOnline = useCallback(() => {
    if (!isConnected) {
      setOnlineWithDelay.start(1000);
    }
    if (isLoading) {
      setIsLoading(false);
    }
  }, [isConnected, isLoading, setOnlineWithDelay]);

  const setOffline = useCallback(() => {
    if (isConnected) {
      setIsConnected(false);
    }
    if (isLoading) {
      setIsLoading(false);
    }
  }, [isConnected, isLoading]);

  useEffect(() => {
    netWorkStatus(isConnected);
  }, [isConnected]);

  const performReachabilityTest = useCallback(async (): Promise<void> => {
    fetchWithTimeout('https://one.one.one.one', {
      mode: 'no-cors',
    })
      .then(() => {
        setOnline();
      })
      .catch(() => {
        setOffline();
      });
  }, [setOffline, setOnline]);

  useEffect(() => {
    Tracer.getInstance().onNetworkStateChange(isConnected);
  }, [isConnected]);

  useEffect(() => {
    const online = netInfo.isConnected;
    if (!!online) {
      performReachabilityTest();
    } else if (online === false) {
      setOffline();
    }
  }, [netInfo.isConnected, performReachabilityTest, setOffline]);

  return (
    <NetworkContext.Provider
      value={{
        isConnected,
        isLoading,
        performReachabilityTest,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};

export const useNetworkStatus = () => {
  const context = useContext(NetworkContext);
  if (!!!context) {
    throw Error('useNetworkStatus must be used inside of NetworkProvider');
  }

  return context;
};
