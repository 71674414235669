import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const TabsStyle = (scale = 1) => {
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          maxWidth: '90%',
          position: 'absolute',
          top: 14,
          zIndex: 999,
          alignSelf: 'center',
          flexDirection: 'row',
          padding: 8,
          borderWidth: 1,
          borderRadius: 6,
          borderColor: theme.colors.grey3,
          backgroundColor: theme.colors.white,
          ...theme.shadow.light,
        },
        tab: {
          minWidth: 74 * scale,
          height: 38 * scale,
          borderRadius: theme.radius.s,
          alignItems: 'center',
          justifyContent: 'center',
          paddingHorizontal: 12,
        },
        text: {
          fontSize: 14 * scale,
          lineHeight: 20 * scale,
          textAlign: 'center',
          fontFamily: theme.fonts.medium,
        },
        activeTab: {
          borderRadius: theme.radius.s,
          backgroundColor: theme.colors.accent,
          ...theme.shadow.light,
        },
      }),
    [scale],
  );
};

export default TabsStyle;
