import React, { FC, useCallback } from 'react';
import { Table } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import useBehaviorSubjectState from '../../../../../hooks/app/useSubjectState';
import {
  TableAction,
  tableActionController,
  tableSelectionController,
  transferItemsVisibilityController,
} from '../Sections/floorViewObservables';

import TransferPreview, {
  TransferItem,
} from '../../../../../components/POS/Modals/TransferPreview/TransferPreview';
import { useTablesData } from '../../../../../hooks/app/tables/useTablesData';
import { canPerformTransfer } from '@oolio-group/order-helper';
import { useNotification } from '../../../../../hooks/Notification';
import { refetchOrderObservable } from '../../../../../hooks/app/orders/ordersObservableUtils';
import TransferSuccess from '../../../../../components/POS/Modals/TransferSuccess/TransferSuccess';
import { useOrderTransferItems } from '../../../../../hooks/orders/useTransferItems';
import SelectTableModal from '../Sections/Modals/SelectTableModal';
import { useModal } from '@oolio-group/rn-use-modal';
import { AppScreen } from '../../../../../types/AppScreen';

interface TransferItemsSidePanel {
  isVisible: Boolean;
  onClose: (bool: boolean) => void;
}

const TransferItemsSidePanel: FC = () => {
  const { showModal, closeModal } = useModal();
  const { translate } = useTranslation();
  const { tableOrdersMap } = useTablesData();
  const { value: selectedTable } = useBehaviorSubjectState<Table | null>(
    tableSelectionController,
  );
  const { setValue: setTableAction } = useBehaviorSubjectState<TableAction>(
    tableActionController,
  );
  const { value: isTransferItemsVisible, setValue: setIsTransferItemsVisible } =
    useBehaviorSubjectState<boolean>(transferItemsVisibilityController);
  const { transferOrderItems } = useOrderTransferItems();
  const { showNotification } = useNotification();

  const onCloseTransferItems = useCallback(() => {
    setIsTransferItemsVisible(false);
  }, [setIsTransferItemsVisible]);

  const onSelectOrderItemsToTransfer = useCallback(
    (sourceOrderItems: TransferItem[]) => {
      const onSelection = async (targetTable: Table, targetOrderId = '') => {
        setIsTransferItemsVisible(false);
        setTableAction(TableAction.DEFAULT);
        if (
          !canPerformTransfer({
            sourceOrderItems: sourceOrderItems.map(item => ({
              ...item,
              tableId: item.table?.id,
            })),
            targetTableId: targetTable.id,
          })
        ) {
          return showNotification({
            message: translate('order.cannotTransferBackToSource'),
            error: true,
          });
        }

        const handlePostTransfer = () => {
          // source table will be same for multiple orders performed in transfer
          const sourceTableId = sourceOrderItems?.[0]?.table?.id;
          refetchOrderObservable.next({ timestamp: Date.now() });
          showModal(
            <TransferSuccess
              items={sourceOrderItems.flatMap(transfer => transfer.orderItems)}
              sourceTable={sourceTableId}
              targetTable={targetTable?.id}
              onClose={onClose}
            />,
          );
        };

        await transferOrderItems({
          targetOrderId,
          targetTable,
          sourceOrderItems,
          postTransfer: handlePostTransfer,
          location: AppScreen.FLOOR_VIEW,
        });
      };
      const onClose = () => {
        closeModal();
      };

      showModal(
        <SelectTableModal
          onSelection={onSelection}
          onClose={onClose}
          canSelectSubTable
        />,
      );
    },
    [
      closeModal,
      setIsTransferItemsVisible,
      setTableAction,
      showModal,
      showNotification,
      transferOrderItems,
      translate,
    ],
  );

  return (
    <>
      {isTransferItemsVisible ? (
        <TransferPreview
          orders={tableOrdersMap[selectedTable?.id ?? ''] ?? []}
          onClose={onCloseTransferItems}
          position="right"
          onSubmit={onSelectOrderItemsToTransfer}
        />
      ) : null}
    </>
  );
};

export default TransferItemsSidePanel;
