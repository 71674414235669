import React, { FC, useCallback, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { OrderStatus } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useSession } from '../../../../hooks/app/useSession';
import { useNotification } from '../../../../hooks/Notification';
import { useSyncOrderEvents } from '../../../../hooks/app/useSyncOrderEvents';
import { userUtility } from '../../../../state/userUtility';
import { generateNotificationEvent } from '../../../../utils/sendReceiptHelper';
import { format } from 'date-fns';
import { PaymentTypeDisplay } from '../OrderHistory';
import { capitalCase } from 'change-case';
import styles from './OrderHistoryTable.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../../components/Icon/Icon';
import Sticker from '../../../../components/Shared/Sticker/Sticker';
import Pagination from '../../../../components/POS/Pagination/Pagination';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import PrintReceiptButton from '../../../../components/POS/Buttons/PrintReceipt/PrintReceiptButton';
import OrderReceiptActionModal from '../../../../components/Modals/SendOrderReceipt/OrderReceiptAction';
import { OrderHistoryItem } from '../../../POS/Orders/OpenOrders/OpenOrders.types';

interface OrderHistoryTableProps {
  data: OrderHistoryItem[];
  isOffline?: boolean;
  onPressRow: (order: OrderHistoryItem) => void;
  onPressReprintDocket: (orderId: string) => void;
  onPressPrintReceipt: (orderId: string, nthPayment?: number) => void;
  currentPage: number;
  onPageChange: (page: number) => void;
  itemsPerPage: number;
}

const OrderHistoryTable: FC<OrderHistoryTableProps> = ({
  data,
  isOffline,
  onPressRow,
  onPressPrintReceipt,
  onPressReprintDocket,
  currentPage,
  onPageChange,
  itemsPerPage,
}) => {
  const [session] = useSession();
  const { showModal } = useModal();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { showNotification } = useNotification();

  const onSyncComplete = () => {
    showNotification({
      success: true,
      message: translate('common.sendSaleReceiptToCustomerSuccess'),
    });
  };
  const { syncOrderEvents } = useSyncOrderEvents(onSyncComplete);

  const pageItems = useMemo(() => {
    return data?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    );
  }, [data, currentPage, itemsPerPage]);

  const sendOrderReceipt = useCallback(
    async (order, email, phone) => {
      if (session && order?.id) {
        const event = generateNotificationEvent(
          session,
          order?.id,
          userUtility.userActivity.posUser?.id || '',
          email,
          phone,
        );

        if (event) {
          syncOrderEvents([event]);
        }
      }
    },
    [session, syncOrderEvents],
  );

  const onPressSendReceipt = useCallback(
    order => {
      if (order) {
        showModal(
          <OrderReceiptActionModal
            order={order}
            sendReceipt={sendOrderReceipt}
          />,
        );
      }
    },
    [sendOrderReceipt, showModal],
  );

  const handleRowPress = useCallback(
    (order: OrderHistoryItem) => {
      if (isOffline) {
        showNotification({
          error: true,
          message: translate('offline.orderHistory'),
        });
      } else {
        onPressRow(order);
      }
    },
    [isOffline, onPressRow, showNotification, translate],
  );

  return (
    <View style={styles.table}>
      <View style={styles.tableHeader}>
        <Text style={[theme.tables.headerText, styles.cellType]}>
          {translate('backOfficeSalesFeed.OrderType')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellNo]}>
          {translate('backOfficeSalesFeed.OrderNo')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellDate]}>
          {translate('onAccount.completedOn')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellName]}>
          {translate('backOfficeSalesFeed.Customers')}
        </Text>
        <Text style={[theme.tables.headerText, styles.cellPayment]}>
          {translate('backOfficeSalesFeed.Payments')}
        </Text>
        <Text
          style={[
            theme.tables.headerText,
            styles.cellAmount,
            styles.headerAmount,
          ]}
        >
          {translate('backOfficeSalesFeed.Total')}
        </Text>
      </View>
      <View style={styles.tableBody}>
        {data.length > 0 ? (
          pageItems.map((order, i) => (
            <TouchableOpacity
              key={i}
              testID="row-OpenOrder"
              style={styles.tableRow}
              onPress={() => handleRowPress(order)}
            >
              <Sticker
                testID="sticker-type"
                type={order.table?.name ? 'neutralLight' : 'teal'}
                label={
                  order.table?.name ? order.table.name : order.orderType.code
                }
                containerStyle={styles.cellType}
              />
              <Text
                testID="order-no"
                style={[
                  styles.cellNo,
                  {
                    color: order.refundOf
                      ? theme.colors.states.negative
                      : theme.colors.dark,
                    fontFamily: theme.fonts.medium,
                  },
                ]}
              >
                {order.orderNumber.substring(0, order.orderNumber.length - 9) ||
                  order.orderNumber}
              </Text>
              <Text testID="order-completedAt" style={styles.cellDate}>
                {format(order.completedAt, 'dd/MM/yyyy hh:mm a')}
              </Text>
              <Text
                testID="order-customer"
                numberOfLines={1}
                style={styles.cellName}
              >
                {order.customerName === 'N/A'
                  ? 'N/A'
                  : capitalCase(order.customerName)}
              </Text>
              <Text
                testID="order-payments"
                numberOfLines={1}
                style={styles.cellPayment}
              >
                {order.payTypes.length > 1
                  ? order.payTypes
                      .map(
                        (payType: PaymentTypeDisplay) =>
                          `${payType.name} (${formatCurrency(payType.amount)})`,
                      )
                      .join(', ')
                  : order.payTypes[0]?.name}
              </Text>
              <Text
                testID="order-amount"
                style={[
                  styles.cellAmount,
                  order.refundOf
                    ? {
                        fontFamily: theme.fonts.medium,
                        color: theme.colors.states.negative,
                      }
                    : null,
                ]}
              >
                {`${order.refundOf ? '-' : ''}${formatCurrency(
                  Math.abs(order.totalValue),
                )}`}
              </Text>
              <PrintReceiptButton
                options={order.payTypes}
                onPrintReceipt={paymentIndex =>
                  onPressPrintReceipt(order.id, paymentIndex)
                }
                reprintDocket={() => {
                  onPressReprintDocket(order.id);
                }}
                orderStatus={OrderStatus.COMPLETED}
                containerStyle={styles.cellButton}
              />
              <ButtonIcon
                testID="btn-send"
                icon="envelope"
                type="neutralLight"
                onPress={() => onPressSendReceipt(order)}
                containerStyle={styles.cellButton}
              />
              <View style={theme.tables.disclosure}>
                <Icon name="angle-right" size={20} color={theme.colors.grey5} />
              </View>
            </TouchableOpacity>
          ))
        ) : (
          <View style={theme.tables.emptyView}>
            <Text style={theme.tables.emptyText}>
              {translate('orderHistory.emptyList')}
            </Text>
          </View>
        )}
      </View>
      {data.length > itemsPerPage ? (
        <Pagination
          pageLength={itemsPerPage}
          page={currentPage}
          onPageChange={onPageChange}
          dataLength={data.length}
        />
      ) : null}
    </View>
  );
};

export default OrderHistoryTable;
