import { useMutation, useReactiveVar } from '@apollo/client/react/hooks';
import {
  getBestPrice,
  getBestPriceOfModifier,
  getTaxFromModifier,
} from '@oolio-group/catalog-helper';
import {
  cloneJSON,
  convertToBoolean,
  getLocaleEntity,
  isStoreProductAvailable,
  isDeselectDefaultOption,
  generateDeselectDefaultOption,
  generateAdjustmentFromVoucher,
  isVoucherAppliedOnOrder,
} from '@oolio-group/client-utils';
import {
  AddModifierEvent,
  AddOrderItemEvent,
  AddOrderItemNotesEvent,
  Adjustment,
  AdjustmentType,
  AppScreen,
  AssignCustomerEvent,
  AssignOrderTypeEvent,
  AssignTableEvent,
  PageItemMaps,
  OrderPaymentEvent,
  Course,
  Customer,
  DEFAULT_ENTITY_ID,
  DEFAULT_OPTION_GROUP,
  DEFAULT_PRICING_GROUP,
  DeviceProfile,
  DEVICE_PRICING_GROUP_ID,
  FireOrderItemsEvent,
  Modifier,
  Order,
  OrderAction,
  OrderEvent,
  OrderItem,
  OrderItemFireStatus,
  OrderItemModifier,
  OrderItemStatus,
  OrderStatus,
  OrderType,
  OrderTypeCode,
  PricingGroup,
  Product,
  Product as ProductAlias,
  RemoveModifierEvent,
  RemoveOrderItemEvent,
  RemoveRewardItemEvent,
  Resource,
  SwitchCourseItemEvent,
  UpdateModifierQuantityEvent,
  UpdateOrderItemPriceEvent,
  UpdateOrderItemQuantityEvent,
  UpdateOrderNotesEvent,
  USER_EXPLICIT_SELECT_PRICING_GROUP,
  Variant,
  VoidReason,
  CompleteOrderEvent,
  FeatureIDs,
  Page,
  Catalogue,
  AssignOrderNameEvent,
  OrderIdentifier,
  DEFAULT_MAX_LIMIT_FOR_ZERO_SELECTION,
  OrderPaymentStatus,
  CatalogueItem,
  ShiftActions,
  RewardAdjustment,
  Voucher,
  RemoveOrderAdjustments,
  AllergensKey,
  AssignOrderAllergensEvent,
  AdjustmentByOrderItemMap,
  FunctionMapActions,
  AddComboEvent,
  ProductPricing,
  Table,
  OriginalProductPricing,
  OTHER_COURSE,
} from '@oolio-group/domain';
import { useTranslation, useLocalization } from '@oolio-group/localization';
import {
  ALL_ALLERGENS_KEY,
  calculateTotalPaidAmount,
  canPerformTransfer,
  computeOrderItemValue,
  getItemsToCheckForPrint,
  getApplicableScheduleAdjustments,
  getScheduleAdjustments,
  isInventoryBeingTracked,
} from '@oolio-group/order-helper';
import { useModal } from '@oolio-group/rn-use-modal';
import { NavigationAction, useIsFocused } from '@react-navigation/core';
import { EventArg, useNavigation, useRoute } from '@react-navigation/native';
import {
  differenceBy,
  differenceWith,
  groupBy,
  isEmpty,
  isEqual,
  keyBy,
  last,
  omit,
  pick,
  sortBy,
  orderBy,
} from 'lodash';
import { useRewards } from '../../../../hooks/orders/useRewards';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MutableRefObject,
} from 'react';
import { View } from 'react-native';
import WarningModal from '../../../../components/Modals/Warning/WarningModal';
import { useCheckFeatureEnabled } from '../../../../hooks/app/features/useCheckFeatureEnabled';
import {
  inventoryProductFragment,
  useProducts,
} from '../../../../hooks/app/products/useProducts';
import { noopHandler } from '../../../../utils/errorHandlers';
import IconButton from '../../../../components/Button/IconButton';
import Cart, {
  CartRewardSelectionState,
  CartSelectionState,
} from '../../../../components/POS/Cart/Cart';
import { CartKeypadActions } from '../../../../components/POS/Cart/CartKeypad/CartKeypadActions';
import Catalog from '../../../../components/Catalog/Catalog';
import {
  CatalogModifier,
  Action,
} from '../../../../components/Catalog/Catalog.type';
import AddNoteModal from '../../../../components/POS/Modals/AddNote/AddNoteModal';
import TransferPreview, {
  TransferItem,
} from '../../../../components/POS/Modals/TransferPreview/TransferPreview';
import AddVoucherModal from '../../../../components/Modals/AddVoucher/AddVoucher';
import ConfirmationModal from '../../../../components/Modals/ConfirmationDialog';
import SearchCustomer from '../../../../components/Modals/Customer/SearchCustomer';
import { CancelOrderItemModalMap } from '../../../../components/Modals/FunctionMaps/CancelOrderItem';
import { PickerModal } from '../../../../components/Modals/Picker/Picker';
import SectionsSelectModal from '../../../../components/Modals/SectionsSelect/SectionsSelectModal';
import SelectTableModal from '../FloorView/Sections/Modals/SelectTableModal';
import SetGuestModal from '../../../../components/Modals/SetGuest/SetGuestModal';
import { SetPriceModal } from '../../../../components/Modals/SetPrice/SetPrice';
import { VariablePriceModal } from '../../../../components/Modals/VariablePrice/VariablePrice';
import { VariableQuantityModal } from '../../../../components/Modals/VariableQuantity/VariableQuantity';
import { SetQuantityModal } from '../../../../components/Modals/SetQuantity/SetQuantity';
import { SetTableNameModal } from '../../../../components/Modals/SetTableName/SetTableName';
import UnFiredItemsWarningModal from '../../../../components/Modals/UnFiredItemsWarningModal/UnFiredItemsWarningModal';
import { useCourses } from '../../../../hooks/app/courses/useCourses';
import { ORDER_SAVE } from '../../../../hooks/app/orders/graphql';
import { useOrders } from '../../../../hooks/app/orders/useOrders';
import {
  postSalesObservableForLogin,
  usePostSalesNavigation,
} from '../../../../hooks/app/usePostSalesNavigation';
import { usePricingGroups } from '../../../../hooks/app/usePricingGroups';
import usePOSUserAuthorization from '../../../../hooks/app/users/usePOSUserAuthorization';
import { useSession } from '../../../../hooks/app/useSession';
import { useCart } from '../../../../hooks/orders/useCart';
import { useOrderTransferItems } from '../../../../hooks/orders/useTransferItems';
import { useNotification } from '../../../../hooks/Notification';
import { useFunctionMaps } from '../../../../hooks/orders/useFunctionMaps';
import { usePrinting } from '../../../../hooks/PrintingProvider';
import useChainModal from '../../../../hooks/useChainModal';
import {
  areProductsAvailabilityUpdatedViaSubscription,
  ordersUpdatedViaSubscription,
  subscriptionDeviceEvent,
} from '../../../../state/cache';
import * as storage from '../../../../storage/interface';
import { AppScreen as POSScreens } from '../../../../types/AppScreen';
import { sortTablesByName } from '../../../../utils/TableHelper';
import { IMap } from '../../../BackOffice/Reports/types';
import { takeOrderUnFocusClearParamController } from './takeOrderObservable';
import { usePaymentTypes } from '../../../../hooks/app/usePaymentTypes';
import { useLoyalty } from '../../../../hooks/app/loyalty/useLoyalty';
import PrinterProfilePrompt from '../../../../components/Modals/PrinterProfilePrompt/PrinterProfilePrompt';
import { useAdjustmentSchedule } from '../../../../hooks/app/useAdjustmentSchedule';
import { useSyncItemAvailability } from '../../../../hooks/app/orders/useSyncItemAvailability';
import LoadingOverlay from '../../../../components/Shared/Loaders/LoadingOverlay';
import { SelectedOptionGroup } from '../../../../types/Options.type';
import { useTokenNumber } from '../../../../hooks/orders/useTokenNumber';
import { useSettings } from '../../../../hooks/app/useSettings';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import styles from './TakeOrder.styles';
import theme from '../../../../common/default-theme';
import ProductAllergens from '../../../../components/Modals/ProductAllergens/ProductAllergens';
import AllergensList from '../../../../components/POS/Modals/AllergensModal/AllergensModal';
import AllergensWarningModal from '../../../../components/POS/Modals/AllergensWarning/AllergensWarning';
import { endOfDay } from 'date-fns';
import { AppliedAdjustment } from '../../../../state/Session';
import * as settings from '../../../../state/preferences';
import { FEATURES } from '../../../../constants';
import { useBatchedCallback } from '../../../../hooks/useBatchedCallback';
import { useOolioLoyalty } from '../../../../hooks/useOolioLoyalty';
import TransferSuccess from '../../../../components/POS/Modals/TransferSuccess/TransferSuccess';
import { globalOrderStore } from '../../../../store/OrderStore';
import { v4 as uuidV4 } from 'uuid';
import { transferItemObservable } from '../../../../hooks/app/orders/ordersObservableUtils';
import useBehaviorSubjectState from '../../../../hooks/app/useSubjectState';
import { usePreviousValue } from '@oolio-group/hooks';

const READ_ONLY_STATUSES = [
  OrderStatus.COMPLETED,
  OrderStatus.REFUNDED,
  OrderStatus.VOID,
];

export interface TakeOrderProps {
  testID?: string;
  allNestedPages: Page[];
  menus: Catalogue[];
  allProducts: Record<string, Product>;
  allVariants: Record<string, Variant>;
  selectedProduct?: Product | Variant;
  onAssignCustomer?: MutableRefObject<Function | null>;
  unAssignCustomer?: MutableRefObject<Function | null>;
  handleSelectProductFromSearch?: MutableRefObject<Function | null>;
  newOrderButtonOnSaveCallback?: MutableRefObject<Function | null>;
  pressPrintReceiptCallback?: MutableRefObject<Function | null>;
  allPageItemMaps: PageItemMaps;
  sortedMenuItems: CatalogueItem[];
  assignedCustomer?: Customer;
}

type RouteParams = {
  orderType: string;
  table: string;
  id: string;
  searchedProduct: Product;
  isCompleted: boolean;
  isExisting: boolean;
  showSpinner?: boolean;
};

interface SetOrderTableChainState {
  sectionId?: string;
  tableId?: string;
  guest?: number;
}

const checkItemRemoved = (item: OrderItemModifier) =>
  pick(item, ['modifierGroupId', 'id', 'unitPrice', 'name']);

const checkItemChange = (item: OrderItemModifier) =>
  pick(item, [
    'modifierGroupId',
    'id',
    'unitPrice',
    'quantity',
    'name',
    'modifierGroupPriority',
  ]);

const TakeOrderContainer: React.FC<TakeOrderProps> = ({
  onAssignCustomer,
  newOrderButtonOnSaveCallback,
  unAssignCustomer,
  handleSelectProductFromSearch,
  pressPrintReceiptCallback,
  menus,
  allNestedPages,
  allProducts,
  allVariants,
  allPageItemMaps,
  sortedMenuItems,
  assignedCustomer,
}) => {
  const route = useRoute();
  const [session] = useSession();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { locale } = useLocalization();
  const safeHeight = theme.useSafeHeight();

  const { showModal, closeModal } = useModal();
  const { pricingGroups, getAllPricingGroupsByStore } = usePricingGroups(true);
  const currentStoreId = session?.currentStore?.id ?? '';
  const { updateCacheProductQuantities, loading } = useProducts(
    undefined,
    inventoryProductFragment,
  );
  const { getTokenNumber } = useTokenNumber();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tokenNumber, setTokenNumber] = useSettings<number>('tokenNumber');

  const { navigateToPostSaleScreen } = usePostSalesNavigation();

  const { paymentTypes } = usePaymentTypes({ fetchPolicy: 'cache-first' });

  const [pricingGroup, setPricingGroup] = useState('');
  const [defaultPricingGroup, setDefaultPricingGroup] = useState('');
  const [selectedCartItem, setSelectedCartItem] =
    useState<CartSelectionState>();
  const [selectedRewardItem, selectRewardItem] =
    useState<CartRewardSelectionState>();
  const [selectedVariantKey, setSelectedVariantKey] = useState('');
  const [courses, setCourses] = useState<Course[]>([]);
  const onFinishSetTable = useRef<Function>();
  const selectedItemAdjustment = useRef<string>('');
  const [inProgressProductQuantities, setInProgressProductQuantities] =
    useState<IMap<number>>({});
  const [selectedSeatNumberStr, setSelectedSeatNumberStr] = useState<
    string | undefined
  >(undefined);

  const selectedSeatNumber = selectedSeatNumberStr
    ? Number(selectedSeatNumberStr)
    : undefined;

  const { showNotification } = useNotification();
  const { applyScheduleAdjustments, applyAdvancedDiscounts } =
    useAdjustmentSchedule();
  const removedScheduleAdjustmentIdsRef = useRef<string[]>([]);
  const guestCountRef = useRef(0);
  const { syncItemAvailability } = useSyncItemAvailability();
  const isFocused = useIsFocused();
  const appliedManualAdjustmentIdRef = useRef<string>('');
  const [localSurcharges, setLocalSurcharges] = useSettings<
    AppliedAdjustment[]
  >(settings.LOCAL_SURCHARGES);
  const params = route.params as RouteParams;
  const isOrderComplete = convertToBoolean(params?.isCompleted);
  const willProceedPayOrderRef = useRef<boolean | undefined>(false);
  const escapeDefaultScreenNavigation = useRef<boolean | undefined>(false);
  const { orderTypes, defaultOrderType, postSaleScreen, enableCourses } =
    session.deviceProfile || {};
  const isFeatureEnabled = useCheckFeatureEnabled();
  const [selectedProduct, setSelectedProduct] = useState<Product | Variant>();
  const [isAdvanceDiscountEnabled, setAdvanceDiscountState] =
    useState<boolean>(false);
  const [isTransferItemsEnabled, setIsTransferItemsEnabled] = useState(false);
  const [localSurchargeList, setLocalSurchargeList] = useState<
    AppliedAdjustment[]
  >(localSurcharges || []);
  const navigateToPayment = () => {
    navigation.navigate('Payment', {
      orderId: order?.id,
      customerAmount: undefined,
      customerId: undefined,
    });
  };
  const [isTransferItemsVisible, setIsTransferItemsVisible] =
    useState<boolean>(false);
  const isLegacyLoyaltyEnabled = isFeatureEnabled(FeatureIDs.LOYALTY);
  const { isLoyaltyEnabled: isNewLoyaltyEnabled } = useOolioLoyalty();
  const isLoyaltyEnabled = isNewLoyaltyEnabled || isLegacyLoyaltyEnabled;
  const { getLoyaltyPrograms, rewardRules, earningRules, loyaltySettings } =
    useLoyalty({
      venueId: session.currentVenue?.id,
      fetchPolicy: 'cache-first',
    });

  const [cacheAndNavigate] = useMutation(ORDER_SAVE, {
    onCompleted: navigateToPayment,
  });

  const { activeUser, canI } = usePOSUserAuthorization();

  const { value: transferItem } = useBehaviorSubjectState<string | null>(
    transferItemObservable,
  );

  const previousTransferItem = usePreviousValue(transferItem);

  const {
    order,
    updateCart,
    isDirty,
    itemsChanged,
    discardChanges,
    setCartParams,
    resetCart,
    clearPriorPendingEvents,
    closeOrderCart,
    openOrderCart,
    recomputeOrderFromServer,
  } = useCart();

  const { courses: allCourses, getCourses } = useCourses();

  const { transferOrderItems } = useOrderTransferItems();

  const courseOptions = useMemo(
    () =>
      allCourses
        .filter(c => c.id !== OTHER_COURSE)
        .map(course => ({ value: course.id, label: course.name })),
    [allCourses],
  );

  const updatedSubscriptionOrders = useReactiveVar<string[]>(
    ordersUpdatedViaSubscription,
  );
  const areProductAvailabilitiesChanged = useReactiveVar<boolean>(
    areProductsAvailabilityUpdatedViaSubscription,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const deviceEventSubscription = useReactiveVar<any>(subscriptionDeviceEvent);

  const selectedOrderType = order?.orderType?.id;
  const orderType = useMemo(
    () => orderTypes?.find(x => x.id === selectedOrderType),
    [selectedOrderType, orderTypes],
  );

  const isCoursesEnabled =
    isFeatureEnabled(FeatureIDs.COURSES, session?.currentVenue?.id) &&
    session.deviceProfile?.enableCourses;

  const orderId = order?.id;

  const { orders, returnOrdersFromCache } = useOrders();

  const dineInOrdersInProgressCache = useMemo(() => {
    return Object.values(orders).filter(
      order => order.orderType?.code === OrderTypeCode.DINE_IN,
    );
  }, [orders]);

  const [proceedPayOrder, setProceedPayOrder] = useState(false);

  const dineInOrdersInProgress = useCallback(() => {
    if (!dineInOrdersInProgressCache.length) {
      const result = returnOrdersFromCache(OrderStatus.IN_PROGRESS);
      return result.filter(
        order => order.orderType?.code === OrderTypeCode.DINE_IN,
      );
    } else {
      return dineInOrdersInProgressCache;
    }
  }, [dineInOrdersInProgressCache, returnOrdersFromCache]);

  useEffect(() => {
    async function getAdvanceDiscountFlag() {
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.CATEGORY_DISCOUNT,
      );

      setAdvanceDiscountState(isEnabled);
    }

    getAdvanceDiscountFlag();
  }, []);

  const getAvailableTablesBySection = useCallback(
    (sectionId: string) => {
      const section = session.deviceProfile?.sections?.find(
        x => x.id === sectionId,
      );
      const inProgressDineInOrders = dineInOrdersInProgress();
      const occupiedTable = inProgressDineInOrders
        .map(order => order.table)
        .filter(table => !!table);
      return differenceBy(section?.tables, occupiedTable, t => t.id);
    },
    [dineInOrdersInProgress, session.deviceProfile?.sections],
  );

  const [cartInitialized, setCartInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (isFocused) {
      escapeDiscardModal.current = false;
      escapeDefaultScreenNavigation.current = false;
      if (params?.id) {
        setCartInitialized(false);
      }
    }
  }, [isFocused, params?.id]);

  const unselectCartItem = useCallback((ignoreProduct?: boolean) => {
    setSelectedCartItem(undefined);
    setSelectedVariantKey('');
    selectRewardItem(undefined);
    !ignoreProduct && setSelectedProduct(undefined);
  }, []);

  const goToNewOrder = useBatchedCallback(async () => {
    postSalesObservableForLogin.next(false);
    await setCartParams(undefined, defaultOrderType?.id, '', false);
    await resetCart((orderId: string) => {
      orderId &&
        navigation.setParams({
          id: orderId,
          isCompleted: false,
          isExisting: false,
          tableId: '',
          orderTypeId: defaultOrderType?.id,
        });
      setCartInitialized(false);
      unselectCartItem();
      isCoursesEnabled && setCourses(allCourses);
    });
  }, [
    setCartParams,
    defaultOrderType?.id,
    resetCart,
    navigation,
    unselectCartItem,
    isCoursesEnabled,
    allCourses,
  ]);

  useEffect(() => {
    if (
      order?.createdBy?.id &&
      order?.createdBy?.id !== activeUser?.id &&
      order.status === OrderStatus.CREATED
    ) {
      //when pos user changed then we create a new order
      goToNewOrder();
    }
  }, [activeUser, goToNewOrder, order?.createdBy?.id, order?.status]);

  const onChangeProductQuantity = useCallback(
    (productId: string | undefined, quantityToReduce: number) => {
      if (!productId) return;
      const product = allProducts[productId];
      const isBeingTracked = isInventoryBeingTracked(
        product?.storesInventory,
        currentStoreId,
      );
      if (isBeingTracked) {
        setInProgressProductQuantities(pre => ({
          ...pre,
          [productId]: (pre[productId] || 0) + quantityToReduce,
        }));
        updateCacheProductQuantities(
          {
            [productId]: -quantityToReduce,
          },
          currentStoreId,
        );
      }
    },
    [allProducts, updateCacheProductQuantities, currentStoreId],
  );

  const resetBackToOriginalQuantities = useCallback(() => {
    const trackedProductIds = Object.keys(inProgressProductQuantities);
    if (!trackedProductIds.length) return;
    updateCacheProductQuantities(inProgressProductQuantities, currentStoreId);
    setInProgressProductQuantities({});
  }, [
    currentStoreId,
    inProgressProductQuantities,
    updateCacheProductQuantities,
  ]);

  const onShowWarningModal = useCallback(
    (productId: string, updateCartCallback: (x?: boolean) => void) => {
      const { name, storesInventory } = allProducts[productId];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const availableQuantity =
        storesInventory?.[currentStoreId]?.availableQuantity || 0;
      const productAvailability = isStoreProductAvailable(
        allProducts[productId],
        currentStoreId,
      );

      let isSubmit: boolean;
      let isSecondConfirmButtonPress: boolean | undefined;

      const onModalCloseCompleted = () => {
        // need to wait modal close completely to trigger other modal like: required options modal
        isSubmit && updateCartCallback(isSecondConfirmButtonPress);
      };

      const onConfirm = (secondButtonConfirm?: boolean) => {
        isSubmit = true;
        isSecondConfirmButtonPress = secondButtonConfirm;
      };

      showModal(
        <WarningModal
          title={translate(
            'takeOrderScreen.itemAvailability.warningModalTitle',
          )}
          description={translate(
            'takeOrderScreen.itemAvailability.warningModalDescription',
            {
              itemName: name,
            },
          )}
          confirmButtonText={translate(
            'takeOrderScreen.itemAvailability.warningModalAddButton',
          )}
          onCancel={noopHandler}
          onConfirm={onConfirm}
          secondConfirmButtonText={
            !productAvailability
              ? ''
              : availableQuantity > 0
              ? translate('takeOrderScreen.itemAvailability.addItem', {
                  availableQuantity,
                })
              : translate('takeOrderScreen.itemAvailability.addItem', {
                  availableQuantity: 0,
                })
          }
          isSecondConfirmButtonDisabled={availableQuantity < 1 && true}
          onConfirmSecondButton={onConfirm}
        />,
        {
          onBackButtonPress: closeModal,
          onModalHide: onModalCloseCompleted,
        },
      );
    },
    [allProducts, closeModal, currentStoreId, showModal, translate],
  );

  const goToPostOrderSaveScreen = useCallback(async () => {
    if (postSaleScreen && postSaleScreen !== AppScreen.NEW_ORDER) {
      await setCartParams(undefined, defaultOrderType?.id, '', false);
      const newOrderId = await resetCart();
      navigation.setParams({
        orderId: newOrderId,
        orderTypeId: defaultOrderType?.id,
        tableId: '',
        isExisting: false,
      });
      if (postSaleScreen == AppScreen.LOGIN) {
        postSalesObservableForLogin.next(true);
      } else {
        navigation.navigate(POSScreens[postSaleScreen]);
      }
    } else {
      await goToNewOrder();
    }
  }, [
    postSaleScreen,
    setCartParams,
    resetCart,
    navigation,
    defaultOrderType?.id,
    goToNewOrder,
  ]);

  const onPressNewOrder = useCallback(async () => {
    await goToPostOrderSaveScreen();
  }, [goToPostOrderSaveScreen]);

  useEffect(() => {
    currentStoreId && getAllPricingGroupsByStore(currentStoreId);
  }, [currentStoreId, getAllPricingGroupsByStore]);

  useEffect(() => {
    if (areProductAvailabilitiesChanged && isDirty) {
      showNotification({
        info: true,
        message: translate(
          'functionMaps.itemAvailability.subscriptionChangesMsg',
        ),
      });
      const productQuantitiesAlreadyRemoved: IMap<number> = {};
      Object.keys(inProgressProductQuantities).forEach((productId: string) => {
        productQuantitiesAlreadyRemoved[productId] =
          -inProgressProductQuantities[productId];
      });
      updateCacheProductQuantities(
        productQuantitiesAlreadyRemoved,
        currentStoreId,
      );
    }
    areProductsAvailabilityUpdatedViaSubscription(false);
  }, [
    areProductAvailabilitiesChanged,
    isDirty,
    inProgressProductQuantities,
    showNotification,
    translate,
    updateCacheProductQuantities,
    currentStoreId,
  ]);

  useEffect(() => {
    if (!isFocused) {
      if (updatedSubscriptionOrders.length > 0)
        ordersUpdatedViaSubscription([]);
      return;
    }

    if (order?.id && updatedSubscriptionOrders.length > 0) {
      const orderIndex = updatedSubscriptionOrders.indexOf(order?.id);
      if (orderIndex !== -1) {
        recomputeOrderFromServer(order?.id);
      }
      // Resetting variable again!
      ordersUpdatedViaSubscription([]);
    }
  }, [
    isFocused,
    goToNewOrder,
    order?.id,
    setCartParams,
    showNotification,
    recomputeOrderFromServer,
    translate,
    updatedSubscriptionOrders,
  ]);

  useEffect(() => {
    if (!cartInitialized) {
      setInProgressProductQuantities({});
      setCartInitialized(true);
      setCartParams(
        params?.id,
        params?.orderType || defaultOrderType?.id,
        params?.table,
        params?.isExisting,
      );
      // TODO add a proper util fn for comparison
      if (params?.id && !order && String(params?.isExisting) == 'false') {
        goToNewOrder();
      }
    }
  }, [
    cartInitialized,
    setCartParams,
    params,
    defaultOrderType,
    order,
    goToNewOrder,
    clearPriorPendingEvents,
  ]);

  useEffect(() => {
    const subscription = takeOrderUnFocusClearParamController.subscribe(() => {
      navigation.setParams({
        id: '',
        orderTypeId: '',
        tableId: '',
        isExisting: false,
      });
    });
    return () => subscription.unsubscribe();
  }, [navigation]);

  const resetDeviceTokenNumberOnShiftClose = useCallback(() => {
    const deviceSettings = session?.device;
    const istTokenEnabled = deviceSettings?.isTokenNumberActive;
    const isResetTokenOnShiftClose =
      istTokenEnabled &&
      deviceSettings.tokenSettings?.resetTokenNumberOnShiftClosure;

    if (isResetTokenOnShiftClose) {
      const range = session?.device?.tokenSettings?.tokenRange;
      if (
        Number.isInteger(range?.start) &&
        (range?.start || range?.start == 0)
      ) {
        setTokenNumber(range?.start);
      }
    }
  }, [session, setTokenNumber]);

  const initiateNewOrderOnShiftClose = useCallback(async () => {
    const currentTokenNumber = await getTokenNumber();
    const device = { ...session.device };
    const isTokenActive = device.isTokenNumberActive;
    const orderTypeId = order?.orderType?.id;
    const isResetTokenOnShiftClose =
      isTokenActive && device.tokenSettings?.resetTokenNumberOnShiftClosure;
    if (
      order &&
      orderTypeId &&
      order?.tokenNumber != currentTokenNumber &&
      isResetTokenOnShiftClose
    ) {
      // initiate new order
      await closeOrderCart();
      setCartParams(undefined, orderTypeId, '', false);
      const newOrderId = await resetCart();
      navigation.navigate('Orders', {
        screen: 'TakeOrder',
        params: {
          id: newOrderId,
          isExisting: false,
          orderType: orderTypeId,
        },
      });
    }
  }, [
    getTokenNumber,
    resetCart,
    order,
    session,
    closeOrderCart,
    navigation,
    setCartParams,
  ]);

  useEffect(() => {
    if (
      deviceEventSubscription &&
      deviceEventSubscription?.action === ShiftActions.SHIFT_CLOSE
    ) {
      resetDeviceTokenNumberOnShiftClose();
      initiateNewOrderOnShiftClose();
      subscriptionDeviceEvent(undefined);
    }
  }, [
    resetDeviceTokenNumberOnShiftClose,
    initiateNewOrderOnShiftClose,
    deviceEventSubscription,
  ]);

  const { printBill, printRecipe } = usePrinting();

  const isTableManagementEnabled =
    session.deviceProfile?.enableFloorView &&
    session.deviceProfile?.sections &&
    session.deviceProfile?.sections.length > 0;

  const isAdvancedKeypad: boolean =
    session?.settings?.showAdvancedCartActionsSetting || false;
  const enableQuickPaymentMode: boolean =
    session?.settings?.enableQuickPaymentModeSetting || false;
  const [advancedKeypadValue, setAdvancedKeypadValue] = useState<number>(0);
  const escapeDiscardModal = useRef(false);
  const autoSaveOrder = useRef(false);
  const autoPayOrder = useRef(false);
  const quickPayFunctionMaps = useRef(false);

  const isItemReadyToFire = useCallback(
    (courseId = DEFAULT_ENTITY_ID) => {
      if (!isCoursesEnabled) return true;
      return !!courses.find(course => course.id === courseId)?.autoFire;
    },
    [courses, isCoursesEnabled],
  );

  useEffect(
    () => setAdvancedKeypadValue(0),
    [session?.settings?.showAdvancedCartActionsSetting],
  );

  useEffect(() => {
    (async () => {
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.TRANSFER_ITEMS,
      );
      const result = !!(isEnabled && isTableManagementEnabled);
      setIsTransferItemsEnabled(result);
    })();
  }, [isTableManagementEnabled]);

  useEffect(() => {
    if (!defaultPricingGroup) {
      const id = Object.keys(pricingGroups).filter(
        key => pricingGroups[key].name === DEFAULT_PRICING_GROUP,
      )[0];
      setDefaultPricingGroup(id);
    }
  }, [defaultPricingGroup, pricingGroups, session]);

  useEffect(() => {
    if (!params?.id) {
      goToNewOrder();
    }
  }, [params?.id, goToNewOrder]);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  useEffect(() => {
    if (!isCoursesEnabled) return;
    setCourses(allCourses);
  }, [isCoursesEnabled, allCourses]);

  // order reward
  const { redeemRewards } = useRewards(rewardRules);

  useEffect(() => {
    if (isLegacyLoyaltyEnabled) {
      getLoyaltyPrograms();
    }
  }, [isLegacyLoyaltyEnabled, getLoyaltyPrograms]);

  useEffect(() => {
    if (params?.id) {
      setSelectedSeatNumberStr(undefined);
      willProceedPayOrderRef.current = false;
    }
  }, [params?.id]);

  const computeRestrictVoidItem = (order?: Order, item?: OrderItem) => {
    if (!order) return false;
    if (!item) return false;
    if (
      !order?.payments?.filter(
        payment => payment?.status == OrderPaymentStatus.COMPLETE,
      ).length
    )
      return false;
    const prepaymentAmount =
      order.payments
        .filter(payment => payment.isPrePayment)
        .reduce((acc, payment) => acc + payment.amount, 0) || 0;
    if (item.quantity > 1) {
      return (
        (item.unitPrice - prepaymentAmount) * item.quantity >
        (order?.amountDue || 0)
      );
    } else {
      return item.unitPrice - prepaymentAmount > (order?.amountDue || 0);
    }
  };

  const isSeatManagementEnabled: boolean =
    (session?.deviceProfile?.enableSeatManagement &&
      orderType?.code == OrderTypeCode.DINE_IN) ||
    false;

  const orderItems = useMemo(() => {
    return (order?.orderItems || [])
      .filter(x => x.status !== OrderItemStatus.CANCELLED)
      .map(x => ({
        ...x,
        ...(x.comboItems?.length && {
          comboItems: x.comboItems.map(item => ({
            ...item,
            product: allProducts[item.product?.id as string],
          })),
        }),
        product: allProducts[x.product.id] || x.product,
        variant: (x.variant && allVariants[x.variant?.id]) || x.variant,
        createdAt: x?.createdAt || Date.now(),
        disabled: x.paymentStatus === OrderPaymentStatus.COMPLETE,
      }))
      .sort((a, b) => a?.createdAt - b?.createdAt) as OrderItem[];
  }, [order, allProducts, allVariants]);

  const pricingGroupOptions = useMemo(() => {
    const pricingGroupsOnStore: Array<PricingGroup> = [];
    Object.keys(pricingGroups).forEach(key => {
      if (
        pricingGroups[key].name !== DEFAULT_PRICING_GROUP &&
        pricingGroups[key].isActive
      ) {
        // we only get pricing groups by current store
        pricingGroupsOnStore.push(pricingGroups[key]);
      }
    });
    const option = pricingGroupsOnStore.map(x => ({
      label: x.name,
      value: x.id,
    }));
    const selectedPricingGroup = option.find(x => x.value == pricingGroup);
    if (selectedPricingGroup) {
      const filteredOptions = option.filter(
        x => x.value != selectedPricingGroup.value,
      );
      return [
        { label: DEFAULT_PRICING_GROUP, value: defaultPricingGroup },
        selectedPricingGroup,
        ...filteredOptions,
      ] as {
        label: string;
        value: string;
      }[];
    }
    return [
      { label: DEFAULT_PRICING_GROUP, value: defaultPricingGroup },
      ...option,
    ] as {
      label: string;
      value: string;
    }[];
  }, [pricingGroups, pricingGroup, defaultPricingGroup]);

  const getPricingGroupId = useCallback(async () => {
    const isPriceListFeatureEnabled = isFeatureEnabled(FeatureIDs.PRICE_LIST);
    if (!isPriceListFeatureEnabled) {
      setPricingGroup(defaultPricingGroup);
      return;
    }

    const userExplicitSelectPricingGroup = await storage.getItem<boolean>(
      USER_EXPLICIT_SELECT_PRICING_GROUP,
    );

    if (userExplicitSelectPricingGroup !== true) {
      setPricingGroup('');
      return;
    }

    const id: string =
      (await storage.getItem(DEVICE_PRICING_GROUP_ID)) || defaultPricingGroup;
    if (pricingGroupOptions.find(x => x.value === id)) {
      setPricingGroup(id);
    } else {
      setPricingGroup(defaultPricingGroup);
    }
  }, [isFeatureEnabled, defaultPricingGroup, pricingGroupOptions]);

  useEffect(() => {
    getPricingGroupId();
  }, [
    getPricingGroupId,
    pricingGroup,
    defaultPricingGroup,
    pricingGroupOptions,
  ]);

  const getVariantProductBasedOnOptions = useCallback(
    (options: string[], variant: Variant) => {
      let variantProducts = variant.products;
      if (options.length) {
        options.forEach(option => {
          variantProducts = variantProducts.filter(
            product =>
              product.optionValues.filter(
                optionValue => optionValue.value === option,
              ).length,
          );
        });
      }
      return variantProducts[0];
    },
    [],
  );

  const pickDefaultVariant = useCallback(
    (variant: Variant) =>
      variant.products.find(prod => prod.isDefault && prod.isSellable),
    [],
  );

  const addToCart = useBatchedCallback(
    (
      productInput: Product,
      variant?: Variant,
      quantity?: number,
      orderItemId?: string,
      priceInput?: number,
    ) => {
      if (isOrderComplete) {
        goToNewOrder();
        return;
      }
      const { id: productId } = productInput;
      const product = allProducts[productId];
      variant?.id && (variant = allVariants[variant.id]);
      const pricing = getBestPrice(product as ProductAlias, {
        orderType: selectedOrderType,
        venue: session.currentVenue?.id,
        store: session.currentStore?.id,
        pricingGroupId: pricingGroup,
      });

      if (pricing) {
        // cancel the action to navigate login screen in case pos sale complete
        postSalesObservableForLogin.next(false);
        const isTrackedProduct = isInventoryBeingTracked(
          allProducts[productId].storesInventory,
          currentStoreId,
        );
        const productQuantity =
          (isTrackedProduct &&
            product?.storesInventory?.[currentStoreId]?.availableQuantity) ||
          0;
        const productAvailableQuantity =
          productQuantity - (quantity || advancedKeypadValue || 1);
        const productAvailability = isStoreProductAvailable(
          product,
          currentStoreId,
        );
        const unitPrice = priceInput
          ? priceInput
          : pricing.sellingPrice?.amount;
        const item = orderItems
          .filter(
            item =>
              item.status === OrderItemStatus.CREATED ||
              item.status === OrderItemStatus.ON_HOLD,
          )
          .find(
            x =>
              !x.notes &&
              !x.discountAmount &&
              !x.saved &&
              x.product.id === productId &&
              x.unitPrice === unitPrice &&
              x.course?.id === product.course?.id &&
              x.seatNumber == selectedSeatNumber,
          );

        if (
          item &&
          item.product?.variableQuantity !== true &&
          (item.product.modifierGroups.length === 0 ||
            (item.product.isCombo &&
              item.product.modifierGroups.every(
                mod =>
                  mod.name === DEFAULT_OPTION_GROUP && mod?.products?.length,
              )))
        ) {
          const onUpdateQuantity = (onlyAddAvailableItems?: boolean) => {
            const quantityToReduce = onlyAddAvailableItems
              ? productQuantity
              : quantity || advancedKeypadValue || 1;
            updateCart<UpdateOrderItemQuantityEvent>(
              OrderAction.ORDER_ITEM_UPDATE_QUANTITY,
              {
                quantity: quantityToReduce,
                orderItemId: item.id,
                inventoryTracked: isTrackedProduct,
                productId,
                ...(item.comboItems?.length && {
                  isCombo: true,
                }),
              },
            );
            onChangeProductQuantity(productId, quantityToReduce);
          };
          if (
            (productAvailableQuantity < 0 &&
              productAvailableQuantity < productQuantity &&
              isTrackedProduct) ||
            !productAvailability
          ) {
            onShowWarningModal(productId, onUpdateQuantity);
          } else {
            onUpdateQuantity();
          }
        } else {
          let modifiers: Array<OrderItemModifier> = [];
          let comboItems: Array<Partial<OrderItem>> | undefined,
            eventId: string;
          if (orderItemId) {
            const orderItem = orderItems.find(x => x.id === orderItemId);
            modifiers = orderItem?.modifiers as Array<OrderItemModifier>;
            comboItems = orderItem?.comboItems || [];
            eventId = uuidV4();
          } else if (
            productInput.isCombo &&
            productInput.modifierGroups.every(
              mod => mod.name === DEFAULT_OPTION_GROUP && mod?.products?.length,
            )
          ) {
            productInput.modifierGroups.forEach(modGroup => {
              modGroup.products?.length &&
                modGroup.products.forEach(prod =>
                  modifiers.push({
                    quantity: 1,
                    unitPrice: prod?.price?.amount || 0,
                    taxes: [],
                    id: prod.id,
                    name: prod.name,
                    modifierGroupId: modGroup.id,
                    alternateNames: [],
                  }),
                );
            });
          }
          const onAddItem = (onlyAddAvailableItems?: boolean) => {
            const quantityToReduce = onlyAddAvailableItems
              ? productQuantity
              : quantity || advancedKeypadValue || 1;
            updateCart<AddOrderItemEvent>(
              OrderAction.ORDER_ITEM_ADD,
              {
                productId,
                productName: product?.name,
                quantity: quantityToReduce,
                costPrice: pricing.costPrice?.amount ?? 0,
                categoryId: variant?.id
                  ? variant?.category?.id
                  : product.category?.id,
                unitPrice: priceInput
                  ? priceInput
                  : pricing.sellingPrice?.amount ?? 0,
                taxInclusive: pricing.taxInclusive ?? false,
                taxes: pricing.sellingTax ? [pricing.sellingTax] : [],
                variantId: variant?.id,
                variantName: variant?.name,
                modifiers,
                fireItem: isItemReadyToFire(product.course?.id),
                printerProfiles: productInput.comboType
                  ? []
                  : product?.printerProfiles?.map(x => x && x.id) || [],
                inventoryTracked: isTrackedProduct,
                seatNumber: isSeatManagementEnabled
                  ? selectedSeatNumber
                  : undefined,
                optionValues: product?.optionValues || [],
                alternateNames: product.alternateNames,
                ...(enableCourses && {
                  courseName: product.course?.name,
                  courseId: product.course?.id,
                }),
                variantAlternateNames: variant?.alternateNames || [],
                variableQuantity: product?.variableQuantity || false,
                measuredBy: product?.measuredBy,
              },
              eventId,
            );
            if (comboItems?.length) {
              createOrUpdateComboProducts(comboItems, eventId);
            }

            onChangeProductQuantity(productId, quantityToReduce);
            setSelectedProduct(product);
          };
          const productQuantity =
            (isTrackedProduct &&
              product?.storesInventory?.[currentStoreId]?.availableQuantity) ||
            0;
          if (
            (productAvailableQuantity < 0 &&
              productAvailableQuantity < productQuantity &&
              isTrackedProduct) ||
            !productAvailability
          ) {
            onShowWarningModal(productId, onAddItem);
          } else {
            onAddItem();
          }
        }
        setAdvancedKeypadValue(0);
      }
    },
    [
      isOrderComplete,
      allProducts,
      allVariants,
      selectedOrderType,
      session.currentVenue?.id,
      session.currentStore?.id,
      pricingGroup,
      goToNewOrder,
      currentStoreId,
      advancedKeypadValue,
      orderItems,
      selectedSeatNumber,
      updateCart,
      onChangeProductQuantity,
      onShowWarningModal,
      isItemReadyToFire,
      isSeatManagementEnabled,
      enableCourses,
    ],
  );

  const proceedToVariableQuantity = useCallback(
    (
      productInput: Product,
      variant?: Variant,
      quantity?: number,
      orderItemId?: string,
      priceInput?: number,
    ) => {
      const isVariableQuantity = productInput?.variableQuantity || false;
      const defaultSize = productInput?.measuredBy?.defaultSize || 1;
      let submitSize: number;
      const onSubmit = (updatedSize: number) => {
        const updatedQuantity = updatedSize / defaultSize;
        addToCart(
          productInput,
          variant,
          updatedQuantity,
          orderItemId,
          priceInput,
        );
      };

      const onCloseModalCompleted = () => {
        if (submitSize === undefined) return;
        onSubmit(submitSize);
      };

      const onStartSubmit = (updatedSize: number) => {
        submitSize = updatedSize;
        closeModal();
      };

      if (isVariableQuantity) {
        if (advancedKeypadValue > 0) {
          onSubmit(advancedKeypadValue);
        } else {
          showModal(
            <VariableQuantityModal
              item={productInput}
              onSubmit={onStartSubmit}
              unitPrice={priceInput}
            />,
            {
              onModalHide: onCloseModalCompleted,
            },
          );
        }
      } else {
        onSubmit(quantity || 0);
      }
    },
    [addToCart, advancedKeypadValue, closeModal, showModal],
  );

  const seatNumberForAllergens = useMemo(() => {
    return String(selectedSeatNumber ?? '') || ALL_ALLERGENS_KEY;
  }, [selectedSeatNumber]);

  const setAllergensToOrder = useCallback(
    (allergens: AllergensKey[]) => {
      updateCart<AssignOrderAllergensEvent>(OrderAction.ORDER_SET_ALLERGENS, {
        seatNumber: selectedSeatNumber,
        allergens,
      });
      closeModal();
    },
    [closeModal, updateCart, selectedSeatNumber],
  );

  const onShowAllergens = useCallback(() => {
    const allergens: AllergensKey[] =
      order?.allergens?.[seatNumberForAllergens] ?? [];
    showModal(
      <AllergensList
        allergens={allergens}
        setAllergensToOrder={setAllergensToOrder}
      />,
    );
  }, [
    order?.allergens,
    showModal,
    setAllergensToOrder,
    seatNumberForAllergens,
  ]);

  const proceedToVariablePricing = useCallback(
    (
      productInput: Product,
      variant?: Variant,
      quantity?: number,
      orderItemId?: string,
    ) => {
      const isVariablePricing = productInput?.variablePricing || false;
      const { id: productId } = productInput;
      const product = allProducts[productId];
      const pricing = getBestPrice(product as ProductAlias, {
        orderType: selectedOrderType,
        venue: session.currentVenue?.id,
        store: session.currentStore?.id,
        pricingGroupId: pricingGroup,
      });
      let submitPriceInput: number;
      const onSubmit = (priceInput: number) => {
        proceedToVariableQuantity(
          productInput,
          variant,
          quantity,
          orderItemId,
          priceInput,
        );
      };

      const onModalClosedComplete = () => {
        if (submitPriceInput == undefined) return;
        onSubmit(submitPriceInput);
      };

      const onStartSubmit = async (priceInput: number) => {
        closeModal();
        submitPriceInput = priceInput;
      };

      if (isVariablePricing) {
        showModal(
          <VariablePriceModal
            item={productInput}
            onSubmit={onStartSubmit}
            defaultPrice={pricing?.sellingPrice?.amount ?? 0}
          />,
          {
            onModalHide: onModalClosedComplete,
          },
        );
      } else {
        proceedToVariableQuantity(
          productInput,
          variant,
          quantity,
          orderItemId,
          pricing?.sellingPrice?.amount ?? 0,
        );
      }
    },
    [
      allProducts,
      closeModal,
      pricingGroup,
      proceedToVariableQuantity,
      selectedOrderType,
      session.currentStore?.id,
      session.currentVenue?.id,
      showModal,
    ],
  );

  const addProductToCart = useCallback(
    (
      productInput: Product,
      variant?: Variant,
      quantity?: number,
      orderItemId?: string,
    ) => {
      const isVariablePricing = productInput.variablePricing || false;
      const isVariableQuantity = productInput.variableQuantity || false;

      if (isVariablePricing || isVariableQuantity) {
        proceedToVariablePricing(productInput, variant, quantity, orderItemId);
      } else {
        addToCart(productInput, variant, quantity, orderItemId);
      }
    },
    [addToCart, proceedToVariablePricing],
  );

  const splitProductFromCart = useCallback(() => {
    const item = orderItems.find(x => x.id === selectedCartItem?.item);
    const modifiers = item?.modifiers as Array<OrderItemModifier>;
    const itemQuantity = item?.quantity;

    if (
      item &&
      (item.status === OrderItemStatus.CREATED ||
        item.status === OrderItemStatus.ON_HOLD) &&
      itemQuantity &&
      itemQuantity > 1
    ) {
      const isTrackedProduct = isInventoryBeingTracked(
        allProducts[item.product.id].storesInventory,
        currentStoreId,
      );
      updateCart<UpdateOrderItemQuantityEvent>(
        OrderAction.ORDER_ITEM_UPDATE_QUANTITY,
        {
          quantity: -(advancedKeypadValue || 1),
          orderItemId: item.id,
          inventoryTracked: isTrackedProduct,
          productId: item.product.id,
          ...(item.comboItems?.length && {
            isCombo: true,
          }),
        },
      );
      updateCart<AddOrderItemEvent>(OrderAction.ORDER_ITEM_ADD, {
        productId: item.product.id,
        productName: item.product.name,
        quantity: advancedKeypadValue || 1,
        costPrice: item.costPrice,
        unitPrice: item.unitPrice,
        taxInclusive: item.taxInclusive,
        taxes: item.taxes,
        variantId: item.variant?.id,
        variantName: item.variant?.name,
        categoryId: item.variant?.id
          ? item.variant.category?.id
          : item.product?.category?.id,
        modifiers,
        fireItem: isItemReadyToFire(item.course?.id),
        printerProfiles: (item?.product?.printerProfiles || [])?.map(
          x => x && x.id,
        ),
        inventoryTracked: isTrackedProduct,
        seatNumber: item.seatNumber,
        optionValues: item?.product?.optionValues || [],
        ...(enableCourses && {
          courseId: item.course?.id,
          courseName: item.course?.name,
        }),
      });

      unselectCartItem();
    }
  }, [
    orderItems,
    selectedCartItem?.item,
    updateCart,
    advancedKeypadValue,
    isItemReadyToFire,
    allProducts,
    unselectCartItem,
    enableCourses,
    currentStoreId,
  ]);

  const isProductQualifiedToBeInCart = useCallback(
    (product: ProductAlias, modifiers: OrderItemModifier[]) => {
      let result = true;
      if (product?.modifierGroups?.length && modifiers?.length) {
        const modifiersInCartWRTGroup = groupBy(
          modifiers,
          mod => mod.modifierGroupId,
        );
        product?.modifierGroups?.forEach(eachModGroup => {
          if (eachModGroup?.isRequired) {
            const modifiersInCart =
              modifiersInCartWRTGroup[eachModGroup?.id] || [];
            const totalNormalModifiersQuantity = modifiersInCart.reduce(
              (sum, modifier) => {
                const defaultModRemove = isDeselectDefaultOption(
                  modifier?.name,
                );
                if (defaultModRemove) {
                  sum -= 1;
                } else {
                  sum += modifier?.quantity || 1;
                }
                return sum;
              },
              0,
            );
            const totalAssumedDefaultMods = eachModGroup.modifiers.reduce(
              (sum, mod) => {
                if (mod.isDefault) {
                  sum += 1;
                }
                return sum;
              },
              0,
            );

            const totalModifiersQuantity =
              totalNormalModifiersQuantity + totalAssumedDefaultMods;
            const min = eachModGroup?.selectionLimit?.min || 1;
            const max =
              eachModGroup?.selectionLimit?.max ||
              DEFAULT_MAX_LIMIT_FOR_ZERO_SELECTION;
            if (
              !(totalModifiersQuantity >= min && totalModifiersQuantity <= max)
            ) {
              result = false;
            }
          }
        });
      }
      return result;
    },
    [],
  );

  const removeItemFromCart = useCallback(
    (itemId: string, type: 'Variant' | 'Product', isForceRemove?: boolean) => {
      const variantProductItem = orderItems.filter((orderItem: OrderItem) =>
        type === 'Variant'
          ? orderItem?.variant?.id === itemId
          : orderItem?.product?.id === itemId,
      );
      if (variantProductItem.length) {
        let isToRemove = true;
        const cartItem = variantProductItem[variantProductItem.length - 1];
        if (cartItem?.product?.modifierGroups?.length) {
          // if product with modifier is not qualified then remove from cart
          isToRemove =
            isForceRemove ||
            !isProductQualifiedToBeInCart(cartItem.product, cartItem.modifiers);
          const localeName = getLocaleEntity(
            cartItem.product,
            locale?.languageTag,
          )?.name;
          isToRemove &&
            // product cannot be included in the Order as you have not selected it's required number of modifiers
            showNotification({
              error: true,
              message: translate('order.orderItemRemoved', {
                name: localeName,
              }),
            });
        }
        const isBeingTracked = isInventoryBeingTracked(
          allProducts[itemId].storesInventory,
          currentStoreId,
        );
        isToRemove &&
          updateCart<RemoveOrderItemEvent>(OrderAction.ORDER_ITEM_REMOVE, {
            orderItemId: cartItem?.id,
            quantity: -cartItem?.quantity,
            inventoryTracked: isBeingTracked,
            productId: itemId,
            ...(cartItem?.comboItems?.length && {
              isCombo: true,
            }),
          });
        isToRemove &&
          cartItem.status !== OrderItemStatus.CANCELLED &&
          onChangeProductQuantity(itemId, -cartItem?.quantity);
        isToRemove && selectedCartItem && setSelectedCartItem(undefined);
      }
    },
    [
      orderItems,
      updateCart,
      allProducts,
      onChangeProductQuantity,
      selectedCartItem,
      isProductQualifiedToBeInCart,
      showNotification,
      translate,
      locale?.languageTag,
      currentStoreId,
    ],
  );

  const isItemMarkedAsAllergic = useCallback(
    (itemAllergens: AllergensKey[] = []) => {
      const orderAllergens = order?.allergens?.[seatNumberForAllergens] ?? [];
      if (!orderAllergens?.length || !itemAllergens?.length) return false;
      return orderAllergens.some(a => itemAllergens?.includes(a));
    },
    [order?.allergens, seatNumberForAllergens],
  );

  const allergensWarningModal = useCallback(
    (product: Product, variant?: Variant, selectedOptions?: string[]) => {
      const onClose = () => {
        closeModal();
        setSelectedProduct(undefined);
      };

      const addToCart = () => {
        closeModal();
        addProductToCart(product, variant);
        selectedOptions?.length && setSelectedProduct(undefined);
      };

      showModal(
        <AllergensWarningModal
          name={(product.name || variant?.name) as string}
          itemAllergens={product.allergens || variant?.allergens}
          orderAllergens={order?.allergens?.[seatNumberForAllergens]}
          addToCart={addToCart}
          onClose={onClose}
        />,
      );
    },
    [
      showModal,
      addProductToCart,
      closeModal,
      order?.allergens,
      seatNumberForAllergens,
    ],
  );

  const viewProductInfoModal = useCallback(
    (product: Product, variant?: Variant, selectedOptions?: string[]) => {
      const onClose = () => {
        closeModal();
        setSelectedProduct(undefined);
      };

      const addToCart = () => {
        const isAllergic = isItemMarkedAsAllergic(
          product.allergens || variant?.allergens,
        );
        if (!isAllergic) {
          closeModal();
          addProductToCart(product, variant);
          selectedOptions?.length && setSelectedProduct(undefined);
        } else {
          allergensWarningModal(product, variant);
        }
      };

      showModal(
        <ProductAllergens
          product={product}
          variant={variant}
          currentStoreId={currentStoreId}
          printRecipe={printRecipe}
          addToCart={addToCart}
          onClose={onClose}
        />,
      );
    },
    [
      showModal,
      addProductToCart,
      closeModal,
      currentStoreId,
      printRecipe,
      isItemMarkedAsAllergic,
      allergensWarningModal,
    ],
  );

  /**
   * @function addVariantProductToCart
   * @description Adds variant product to the cart.
   * @param {Array} selectedOptions - The variant option that is being added to the cart.
   * @param {Object} variant - The product(catalogue) or the parent with variant.
   */

  const addVariantProductToCart = useCallback(
    (selectedOptions: string[], variant: Variant, isLongPress?: boolean) => {
      if (variant) {
        const defaultVariant = pickDefaultVariant(variant);
        const isAllergic = isItemMarkedAsAllergic(
          defaultVariant?.allergens || variant.allergens,
        );
        if (defaultVariant && !selectedOptions?.length) {
          defaultVariant['stores'] = variant['stores'];
          if (isLongPress) {
            viewProductInfoModal(defaultVariant, variant, selectedOptions);
          } else {
            isAllergic
              ? allergensWarningModal(defaultVariant, variant)
              : addProductToCart(defaultVariant, variant);
          }
        } else if (selectedOptions?.length) {
          const variantProduct = Object.assign(
            {},
            getVariantProductBasedOnOptions(selectedOptions, variant),
          );
          const isAllergic = isItemMarkedAsAllergic(
            variantProduct?.allergens || variant.allergens,
          );

          if (variantProduct) {
            variantProduct['stores'] = variant['stores'];
            if (isLongPress) {
              viewProductInfoModal(variantProduct, variant, selectedOptions);
            } else {
              isAllergic
                ? allergensWarningModal(variantProduct, variant)
                : addProductToCart(variantProduct, variant);
            }
          }
        } else {
          setSelectedProduct(variant);
        }
      }
    },
    [
      pickDefaultVariant,
      allergensWarningModal,
      isItemMarkedAsAllergic,
      addProductToCart,
      getVariantProductBasedOnOptions,
      viewProductInfoModal,
    ],
  );

  const replaceVariantInTheCart = useCallback(
    (orderItem, selectedProduct, selectedOptions) => {
      if (selectedCartItem && selectedCartItem.variant) {
        const itemInCart = order?.orderItems.find(
          item => item.id === orderItem,
        );
        const quantity = itemInCart?.quantity || 1;
        const selectedVariantProduct = (
          selectedProduct as Variant
        )?.products?.find(x => x.id === selectedCartItem.product);

        const matchingOptionsOfSelectedProduct =
          selectedVariantProduct?.optionValues?.filter(
            x => x && !selectedOptions.includes(x.value) && x.value,
          );

        const hasNestedModifiers =
          selectedVariantProduct?.modifierGroups?.length;

        if (
          (matchingOptionsOfSelectedProduct?.length || hasNestedModifiers) &&
          selectedCartItem.product
        ) {
          const isBeingTracked = isInventoryBeingTracked(
            allProducts[selectedCartItem.product].storesInventory,
            currentStoreId,
          );
          updateCart<RemoveOrderItemEvent>(OrderAction.ORDER_ITEM_REMOVE, {
            orderItemId: orderItem,
            quantity: -quantity,
            inventoryTracked: isBeingTracked,
            productId: selectedCartItem.product,
          });

          itemInCart?.status !== OrderItemStatus.CANCELLED &&
            onChangeProductQuantity(selectedCartItem.product, -quantity);

          const variantProduct = Object.assign(
            {},
            getVariantProductBasedOnOptions(selectedOptions, selectedProduct),
          );
          variantProduct['stores'] = selectedProduct['stores'];
          addProductToCart(variantProduct, selectedProduct, quantity);
          unselectCartItem(true);
        } else {
          unselectCartItem();
        }
      }
      setSelectedVariantKey('');
    },
    [
      unselectCartItem,
      selectedCartItem,
      order?.orderItems,
      allProducts,
      currentStoreId,
      updateCart,
      onChangeProductQuantity,
      getVariantProductBasedOnOptions,
      addProductToCart,
    ],
  );

  const getModifierDetails = useCallback(
    (productId, modifierGroupId, modifierId) => {
      const modGroups =
        (allProducts[productId] as ProductAlias)?.modifierGroups || [];
      const selectedModifierGroup = modGroups.filter(
        x => x.id === modifierGroupId,
      );
      const selectedModifierData =
        selectedModifierGroup?.[0]?.modifiers.filter(
          x => x.id === modifierId,
        ) || [];

      return selectedModifierData;
    },
    [allProducts],
  );

  const addOrRemoveModifier = useCallback(
    (selectedProd, selectedModifier, orderItemId) => {
      const selectedModifierDetails = getModifierDetails(
        selectedProd?.id,
        selectedModifier?.modifierGroup,
        selectedModifier?.id,
      );
      const isDefaultModRemoved =
        selectedModifier?.isDefault && selectedModifier?.isSelected;
      const isModToAdd = selectedModifier?.isDefault
        ? isDefaultModRemoved
        : selectedModifier?.id && !selectedModifier?.isSelected;
      if (isModToAdd) {
        // adds modifier to order item
        const bestPrice = getBestPriceOfModifier(selectedModifierDetails[0]);
        const tax = getTaxFromModifier(selectedModifierDetails[0]);
        const sortedItems = orderBy(order?.orderItems, ['createdAt'], ['asc']);
        const recentOrderItem =
          orderItemId || sortedItems?.[sortedItems?.length - 1]?.id;
        const selectedModfierData = {
          ...(selectedModifierDetails?.[0] || {}),
          selectedModifier,
        };
        const modName = selectedModfierData?.name || '';
        if (recentOrderItem) {
          updateCart<AddModifierEvent>(OrderAction.ORDER_ITEM_ADD_MODIFIER, {
            modifierId: selectedModifier.id,
            orderItemId: recentOrderItem,
            quantity: advancedKeypadValue || 1,
            taxes: tax,
            unitPrice: isDefaultModRemoved ? 0 : bestPrice,
            name: isDefaultModRemoved ? `- ${modName}` : modName,
            modifierGroupId: selectedModifier.modifierGroup,
            modifierGroupPriority: selectedModifier?.modifierGroupPriority,
            alternateNames:
              (selectedModfierData as Modifier)?.alternateNames || [],
          });
          setAdvancedKeypadValue(0);
        }
      } else if (!isModToAdd) {
        // removes modifier from order item
        const recentOrderItem =
          orderItemId || order?.orderItems?.[order?.orderItems?.length - 1]?.id;
        recentOrderItem &&
          updateCart<RemoveModifierEvent>(
            OrderAction.ORDER_ITEM_REMOVE_MODIFIER,
            {
              modifierId: selectedModifier.id,
              orderItemId: recentOrderItem,
              modifierGroupId: selectedModifier.modifierGroup,
            },
          );
      }
    },
    [getModifierDetails, order?.orderItems, updateCart, advancedKeypadValue],
  );

  const addModifierToCart = useCallback(
    (
      selectedProd: Partial<Product>,
      selectedModifiers: CatalogModifier[],
      orderItemId,
    ) => {
      if (selectedModifiers.length) {
        selectedModifiers.forEach(eachSelectedMod =>
          addOrRemoveModifier(selectedProd, eachSelectedMod, orderItemId),
        );
      } else {
        addProductToCart(selectedProd as Product);
      }
    },
    [addProductToCart, addOrRemoveModifier],
  );

  const areCartItemsValid = useCallback(
    (errorMessage: string): boolean => {
      let isValid = true;
      let productName = '';

      if (orderItems?.length > 0) {
        for (let i = orderItems.length - 1; i >= 0; i--) {
          const orderItem = orderItems?.[i];
          const isItemValid = isProductQualifiedToBeInCart(
            orderItem.product,
            orderItem.modifiers,
          );
          if (!isItemValid) {
            isValid = false;
            productName = orderItem?.product?.name;
            break;
          }
        }
      }

      !isValid &&
        showNotification({
          error: true,
          message: translate(errorMessage, {
            name: productName,
          }),
        });

      return isValid;
    },
    [isProductQualifiedToBeInCart, orderItems, showNotification, translate],
  );

  const payOrder = useCallback(
    (passedOrder?: Order): void => {
      escapeDiscardModal.current = true;
      const callEvent = areCartItemsValid('order.incorrectOrder');
      callEvent &&
        cacheAndNavigate({ variables: { data: passedOrder || order } });
      autoPayOrder.current = false;
    },
    [areCartItemsValid, cacheAndNavigate, order],
  );

  // FIXME: This needs to work within useCallback itself.
  // But right now Order always picks previous state (inside callback)
  // which is why table number gets neglected
  useEffect(() => {
    if (proceedPayOrder) {
      payOrder(order);
      setProceedPayOrder(false);
    }
  }, [order, proceedPayOrder, payOrder]);

  const assignCustomerToOrder = useCallback(
    (customer: Customer): void => {
      // trigger assign order to customer event
      updateCart<AssignCustomerEvent>(OrderAction.ORDER_ASSIGN_CUSTOMER, {
        customerId: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        loyaltyMember: customer.loyaltyMember,
        customerAccountDetails: isEmpty(customer?.customerAccountDetails)
          ? {}
          : {
              accountPayment: customer?.customerAccountDetails?.accountPayment,
              currentBalance: customer?.customerAccountDetails?.currentBalance,
              maxBalanceLimit:
                customer?.customerAccountDetails?.maxBalanceLimit,
              maxOrderLimit: customer?.customerAccountDetails?.maxOrderLimit,
            },
        isLoyaltyApplied: Boolean(
          isLegacyLoyaltyEnabled && customer.loyaltyMember,
        ),
      });
      // reset rewards
      updateCart(OrderAction.ORDER_REWARD_RESET);
    },
    [isLegacyLoyaltyEnabled, updateCart],
  );

  const fireOrderItemsHandler = useCallback(
    (fireOrderItems: OrderItemFireStatus[]) => {
      if (!fireOrderItems.length) return;
      if (order?.status === OrderStatus.ON_HOLD) return;

      updateCart<FireOrderItemsEvent>(OrderAction.ORDER_FIRE_ITEMS, {
        orderItemsToFire: fireOrderItems,
      });
    },
    [updateCart, order?.status],
  );

  const handleToggleAutoFire = useCallback(
    (inputCourseIds: string | string[]) => {
      const courseIds =
        typeof inputCourseIds === 'string' ? [inputCourseIds] : inputCourseIds;

      setCourses(prevCourses => {
        const orderItemsToFire: OrderItemFireStatus[] = [];
        const updatedCourses = prevCourses.map(course => {
          const { id: courseId } = course;
          if (courseIds.includes(courseId)) {
            const orderItemIdsByCourse = orderItems
              .filter(orderItem => {
                if (courseId === DEFAULT_ENTITY_ID)
                  return !orderItem.course?.id;
                return orderItem.course?.id === courseId;
              })
              .map(orderItem => ({
                id: orderItem.id,
                status: !course.autoFire,
              })) as OrderItemFireStatus[];

            orderItemsToFire.push(...orderItemIdsByCourse);

            return {
              ...course,
              autoFire: !course.autoFire,
            };
          }
          return course;
        });

        fireOrderItemsHandler(orderItemsToFire);
        return updatedCourses;
      });
    },
    [orderItems, fireOrderItemsHandler],
  );

  const unassignCustomerToOrder = useCallback((): void => {
    // trigger assign order to customer event
    updateCart<OrderEvent>(OrderAction.ORDER_UNASSIGN_CUSTOMER);
    // reset cart
    updateCart(OrderAction.ORDER_REWARD_RESET);
  }, [updateCart]);

  const onSelectCartItem = useCallback(
    (state: CartSelectionState) => {
      if (!isOrderComplete) {
        if (state.selectedVariantKey) {
          setSelectedVariantKey(state.selectedVariantKey);
        } else {
          setSelectedVariantKey('');
        }
        setSelectedCartItem(currentState => {
          if (state.combo) {
            return state;
          }

          if (
            state.selectedItemKeyForAdjustment &&
            currentState?.item !== state.item
          ) {
            setSelectedVariantKey('');
            setSelectedProduct(undefined);
            return state;
          }
          //This will unselected the modifier when the selected modifier is clicked again in the cart.
          if (
            state.selectedModifierKey &&
            currentState?.selectedModifierKey !== state.selectedModifierKey
          ) {
            return state;
          }
          if (currentState?.item === state.item) {
            setSelectedProduct(undefined);
            return undefined;
          }
          return state;
        });
        selectRewardItem(undefined);
      }
    },
    [isOrderComplete],
  );

  const onSelectRewardItem = useCallback(
    (state: CartRewardSelectionState) => {
      if (!isOrderComplete) {
        selectRewardItem(currentState => {
          if (currentState?.reward === state.reward) {
            return undefined;
          }
          return state;
        });
        setSelectedCartItem(undefined);
        setSelectedProduct(undefined);
      }
    },
    [isOrderComplete],
  );

  const getOrderItemsWithNoPrinterProfilesAssigned = useCallback(() => {
    const items = getItemsToCheckForPrint(orderItems);
    return items?.filter(item => !item.product?.printerProfiles?.length);
  }, [orderItems]);

  /**
   * Filters out adjustments manually removed using Remove Surcharge or Remove Discount button
   * @param adjustments
   * @returns An array of adjustments not removed manually
   * @returns An AdjustmentByOrderItemMap
   */
  const excludeRemovedScheduleAdjustments = useCallback(
    (
      adjustments?: Adjustment[],
      adjustmentByOrderItem?: AdjustmentByOrderItemMap,
    ) => {
      let orderAdjustments: Adjustment[] = adjustments || [];
      const filteredAdjustmentByItem = {};
      if (orderAdjustments?.length) {
        orderAdjustments = orderAdjustments.filter(
          a =>
            !removedScheduleAdjustmentIdsRef.current.includes(a.id as string),
        );
      }
      if (Object.keys(adjustmentByOrderItem || {})?.length) {
        const itemIds = Object.keys(
          adjustmentByOrderItem as AdjustmentByOrderItemMap,
        );
        itemIds.forEach(id => {
          if (
            !removedScheduleAdjustmentIdsRef.current.includes(
              adjustmentByOrderItem?.[id]?.adjustment?.id as string,
            )
          ) {
            filteredAdjustmentByItem[id] = {
              ...adjustmentByOrderItem?.[id],
            } as AdjustmentByOrderItemMap;
          }
        });
      }
      return {
        orderAdjustments,
        adjustmentByOrderItem:
          filteredAdjustmentByItem as AdjustmentByOrderItemMap,
      };
    },
    [],
  );

  /**
   * Filter and transform an array of AppliedAdjustment objects to include only those
   * with timestamps representing future dates (up to the end of the current day).
   *
   * @param surcharges - An array of AppliedAdjustment objects with timestamps.
   * @returns An array of valid surcharges with timestamps for future dates,
   *          omitting the 'timestamp' property from each object.
   */
  const getValidSurcharges = useCallback(
    (surcharges: AppliedAdjustment[]) => {
      const validLocalAdjustments = surcharges.filter(
        surcharge => Date.now() < endOfDay(surcharge.timestamp).getTime(),
      );
      // Check if there are changes before updating the storage
      if (surcharges.length !== validLocalAdjustments.length) {
        setLocalSurcharges(validLocalAdjustments);
      }
      return excludeRemovedScheduleAdjustments(
        validLocalAdjustments.map(s => omit(s, ['timestamp'])),
      ).orderAdjustments;
    },
    [excludeRemovedScheduleAdjustments, setLocalSurcharges],
  );

  const onLocalSurchageChange = useCallback(
    (surcharges: AppliedAdjustment[] | undefined) => {
      if (surcharges) {
        setLocalSurchargeList(surcharges);
      }
    },
    [],
  );

  useEffect(() => {
    storage.addSubscription(settings.LOCAL_SURCHARGES, onLocalSurchageChange);
    return () => {
      storage.removeSubscription(
        settings.LOCAL_SURCHARGES,
        onLocalSurchageChange,
      );
    };
  }, [onLocalSurchageChange]);

  /*
    This will check for schedule adjustments when we hit on Send button
    Without this schedule adjustment missed if user directly hit on Send button and then add guest count
  */
  const findAndApplyScheduleAdjustments = useCallback(() => {
    // Only surcharges has guest rule which can be check while sending order
    const adjustments = session.currentVenue?.adjustments?.filter(
      a => a.adjustmentType === AdjustmentType.SURCHARGE,
    );

    const scheduleAdjustments = getScheduleAdjustments(
      adjustments,
      order?.orderItems,
      {
        selectedOrderType,
        guestCount: guestCountRef.current,
        storeId: currentStoreId,
      },
    );

    // If scheduleAdjustments is already been applied then it won't applied again
    if (
      localSurchargeList?.length ||
      scheduleAdjustments?.validAdjustments.length
    ) {
      const adjustments = getApplicableScheduleAdjustments(
        scheduleAdjustments?.validAdjustments,
        order?.adjustments,
      );
      const filteredAdjustments =
        excludeRemovedScheduleAdjustments(adjustments).orderAdjustments;
      const validLocalSurchages = getValidSurcharges(localSurchargeList);
      (validLocalSurchages?.length || filteredAdjustments?.length) &&
        applyScheduleAdjustments([
          ...(order?.adjustments ?? []),
          ...(validLocalSurchages?.length > 0
            ? validLocalSurchages
            : filteredAdjustments),
        ]);
    }
  }, [
    session.currentVenue?.adjustments,
    order?.orderItems,
    order?.adjustments,
    selectedOrderType,
    currentStoreId,
    localSurchargeList,
    excludeRemovedScheduleAdjustments,
    getValidSurcharges,
    applyScheduleAdjustments,
  ]);

  const saveOrder = useBatchedCallback(
    async (callback?: Function) => {
      findAndApplyScheduleAdjustments();
      escapeDiscardModal.current = true;
      autoSaveOrder.current = false;
      const callEvent = areCartItemsValid('order.incorrectOrder');

      callEvent && updateCart(OrderAction.ORDER_SAVE);
      await closeOrderCart();
      if (!escapeDefaultScreenNavigation.current)
        callEvent && (await goToPostOrderSaveScreen());
      else goToNewOrder();
      syncItemAvailability();
      callback?.();
      escapeDiscardModal.current = false;
    },
    [
      areCartItemsValid,
      updateCart,
      closeOrderCart,
      goToPostOrderSaveScreen,
      goToNewOrder,
      syncItemAvailability,
      findAndApplyScheduleAdjustments,
    ],
  );

  const saveOrderInvocation = useCallback(
    async (callback?: Function) => {
      const orderItemsWithNoPrinterProfilesAssigned =
        getOrderItemsWithNoPrinterProfilesAssigned();

      if (orderItemsWithNoPrinterProfilesAssigned.length) {
        showModal(
          <PrinterProfilePrompt
            orderItems={orderItemsWithNoPrinterProfilesAssigned as OrderItem[]}
            onConfirm={() => saveOrder(callback)}
          />,
        );
      } else {
        saveOrder(callback);
      }
    },
    [getOrderItemsWithNoPrinterProfilesAssigned, showModal, saveOrder],
  );

  const onAssignCustomerToOrder = useCallback(
    customer => {
      assignCustomerToOrder(customer);
      if (autoSaveOrder.current) saveOrderInvocation();
      closeModal();
    },
    [saveOrderInvocation, assignCustomerToOrder, closeModal],
  );

  const onClickCustomer = useCallback(
    () =>
      !isOrderComplete &&
      showModal(
        <SearchCustomer
          orderId={orderId}
          assignCustomerToOrder={onAssignCustomerToOrder}
          unassignCustomerToOrder={unassignCustomerToOrder}
          onClose={autoSaveOrder.current ? saveOrderInvocation : undefined}
          assignedCustomer={assignedCustomer}
          onRewardRedeem={redeemRewards}
          rewardRules={rewardRules}
          loyaltySettings={loyaltySettings}
        />,
        {
          onBackdropPress: closeModal,
        },
      ),
    [
      isOrderComplete,
      showModal,
      orderId,
      assignedCustomer,
      closeModal,
      unassignCustomerToOrder,
      saveOrderInvocation,
      onAssignCustomerToOrder,
      redeemRewards,
      loyaltySettings,
      rewardRules,
    ],
  );

  const onConfirmSetQuantity = useCallback(
    (quantity: number, reason?: VoidReason) => {
      if (selectedCartItem) {
        const item = orderItems.find(x => x.id === selectedCartItem.item);
        const quantityToBeUpdated = quantity - (item?.quantity || 0);
        const productQuantity =
          allProducts[item?.product?.id || '']?.storesInventory?.[
            currentStoreId
          ]?.availableQuantity || 0;
        const productAvailableQuantity = productQuantity - quantityToBeUpdated;

        if (
          quantityToBeUpdated > 0 &&
          !(item?.status === OrderItemStatus.CREATED) &&
          !selectedCartItem?.modifier
        ) {
          addProductToCart(
            { id: item?.product?.id, name: item?.product?.name } as Product,
            { id: item?.variant?.id, name: item?.variant?.name } as Variant,
            quantityToBeUpdated,
            item?.id,
          );
        } else if (
          selectedCartItem?.modifier &&
          selectedCartItem?.modifierGroup
        ) {
          const selectedModifierDetails = getModifierDetails(
            selectedCartItem?.product,
            selectedCartItem?.modifierGroup,
            selectedCartItem?.modifier,
          );
          if (selectedModifierDetails?.length) {
            updateCart<UpdateModifierQuantityEvent>(
              OrderAction.ORDER_ITEM_UPDATE_MODIFIER_QUANTITY,
              {
                modifierId: selectedCartItem.modifier,
                orderItemId: selectedCartItem.item,
                quantity: quantity,
                modifierGroupId: selectedCartItem.modifierGroup,
              },
            );
          }
        } else {
          const allowVoidItem = canI(
            [{ onResource: Resource.VOID_ORDER_ITEMS }],
            {
              prompt: true,
            },
          );
          if (!allowVoidItem) return;
          if (Boolean(computeRestrictVoidItem(order, item))) {
            showNotification({
              error: true,
              message: translate('order.voidPartiallyPaid'),
            });
            return;
          }
          if (quantity > 0) {
            const onUpdateQuantity = (onlyAddAvailableItems?: boolean) => {
              const quantityToReduce = onlyAddAvailableItems
                ? productQuantity
                : quantityToBeUpdated;
              const isBeingTracked = isInventoryBeingTracked(
                allProducts[item?.product?.id || '']?.storesInventory,
                currentStoreId,
              );
              updateCart<UpdateOrderItemQuantityEvent>(
                OrderAction.ORDER_ITEM_UPDATE_QUANTITY,
                {
                  quantity: quantityToReduce,
                  orderItemId: selectedCartItem.item,
                  reason: reason,
                  inventoryTracked: isBeingTracked,
                  productId: item?.product?.id || '',
                  ...(item?.comboItems?.length && {
                    isCombo: true,
                  }),
                },
              );

              item?.status !== OrderItemStatus.CANCELLED &&
                onChangeProductQuantity(item?.product?.id, quantityToReduce);
            };
            const isBeingTracked = isInventoryBeingTracked(
              allProducts[item?.product?.id || '']?.storesInventory,
              currentStoreId,
            );
            const productAvailability = isStoreProductAvailable(
              allProducts[item?.product?.id || ''],
              currentStoreId,
            );
            if (
              (productAvailableQuantity < 0 &&
                productAvailableQuantity < productQuantity &&
                isBeingTracked) ||
              !productAvailability
            ) {
              onShowWarningModal(item?.product?.id || '', onUpdateQuantity);
            } else {
              onUpdateQuantity();
            }
          } else {
            const isBeingTracked = isInventoryBeingTracked(
              allProducts[item?.product?.id || '']?.storesInventory,
              currentStoreId,
            );

            if (isLoyaltyEnabled && assignedCustomer?.loyaltyMember) {
              const rewardItems = (item?.adjustments || []).filter(
                adj => adj.adjustmentType == AdjustmentType.REWARD,
              ) as RewardAdjustment[];

              if (rewardItems?.length) {
                rewardItems.forEach(reward => {
                  updateCart<RemoveRewardItemEvent>(
                    OrderAction.ORDER_REWARD_REMOVE,
                    {
                      rewardId: reward.id,
                      productId: item?.product?.id || '',
                    },
                  );
                });
              }
            }

            updateCart<RemoveOrderItemEvent>(OrderAction.ORDER_ITEM_REMOVE, {
              orderItemId: selectedCartItem.item,
              reason,
              quantity: -(item?.quantity || 0),
              inventoryTracked: isBeingTracked,
              productId: item?.product?.id || '',
              ...(item?.comboItems?.length && {
                isCombo: true,
              }),
            });

            item?.status !== OrderItemStatus.CANCELLED &&
              onChangeProductQuantity(
                item?.product?.id,
                -(item?.quantity || 0),
              );
          }
          unselectCartItem();
        }
        setAdvancedKeypadValue(0);
      }
    },
    [
      selectedCartItem,
      orderItems,
      allProducts,
      currentStoreId,
      addProductToCart,
      getModifierDetails,
      updateCart,
      canI,
      order,
      unselectCartItem,
      showNotification,
      translate,
      onChangeProductQuantity,
      onShowWarningModal,
      isLoyaltyEnabled,
      assignedCustomer?.loyaltyMember,
    ],
  );

  const isOrderItemValidAfterModifierDeletion = useCallback(
    (orderItem: OrderItem, selectedCartItem) => {
      const modifierI = orderItem?.modifiers?.findIndex(
        modifier =>
          modifier.id === selectedCartItem.modifier &&
          modifier.modifierGroupId === selectedCartItem.modifierGroup,
      ) as number;

      modifierI >= 0 && orderItem?.modifiers.splice(modifierI, 1);

      return isProductQualifiedToBeInCart(
        orderItem?.product as Product,
        orderItem?.modifiers as OrderItemModifier[],
      );
    },
    [isProductQualifiedToBeInCart],
  );

  const resetAppliedRef = useCallback(() => {
    guestCountRef.current = 0;
    removedScheduleAdjustmentIdsRef.current = [];
    appliedManualAdjustmentIdRef.current = '';
  }, []);

  const setRemovedScheduleAdjustmentIds = useCallback(
    (type?: AdjustmentType, filteredRemovedAdjustmentId?: string[]) => {
      /*
        If we apply adjustment from function map then it should be filtered and
        removed from removedScheduleAdjustmentIdsRef so that it can auto apply
      */
      if (filteredRemovedAdjustmentId?.length) {
        removedScheduleAdjustmentIdsRef.current =
          removedScheduleAdjustmentIdsRef.current.filter(
            removedAdjId => !filteredRemovedAdjustmentId.includes(removedAdjId),
          );
      }
      if (type) {
        const adjustmentIds = order?.adjustments
          ?.filter(
            a =>
              a.adjustmentType === type ||
              localSurchargeList.some(item => item.id === (a.id ?? '')),
          )
          .map(a => a.id) as string[];
        const adjustmentItemIds: string[] = [];
        order?.orderItems?.forEach(item => {
          item.adjustments?.forEach(a => {
            if (a.adjustmentType === type) {
              adjustmentItemIds.push(a.id as string);
              if (appliedManualAdjustmentIdRef.current === a.id)
                appliedManualAdjustmentIdRef.current = '';
            }
          });
        });

        removedScheduleAdjustmentIdsRef.current = [
          ...new Set([
            ...removedScheduleAdjustmentIdsRef.current,
            ...(adjustmentIds ?? []),
            ...(adjustmentItemIds ?? []),
          ]),
        ];
      }
    },
    [localSurchargeList, order?.adjustments, order?.orderItems],
  );

  const clearCart = useCallback(() => {
    discardChanges();
    closeOrderCart();
    resetBackToOriginalQuantities();
    closeModal();
  }, [
    closeModal,
    discardChanges,
    resetBackToOriginalQuantities,
    closeOrderCart,
  ]);

  const calculateReductionAmount = useCallback(
    (orderItem: OrderItem, quantityToBeKept?: number) => {
      const amountPerItem =
        computeOrderItemValue(orderItem) / orderItem.quantity;

      const reductionQuantity = quantityToBeKept
        ? orderItem.quantity - quantityToBeKept
        : orderItem.quantity;

      return amountPerItem * reductionQuantity;
    },
    [],
  );

  const isValidTotalPriceBeforeRemoveItem = useCallback(
    (quantityToBeKept?: number) => {
      const orderItemsCopy = cloneJSON(orderItems);
      const selectedOrderItem = orderItemsCopy.find(
        (orderItem: OrderItem) => orderItem.id === selectedCartItem?.item,
      );

      if (!selectedOrderItem) return false;
      const activeOrderItems = orderItemsCopy.filter((orderItem: OrderItem) =>
        [OrderItemStatus.CREATED, OrderItemStatus.IN_PROGRESS].includes(
          orderItem.status,
        ),
      );
      if (activeOrderItems?.length === 1) return true;
      let reductionAmount = 0;

      if (selectedOrderItem.status === OrderItemStatus.IN_PROGRESS) {
        reductionAmount = calculateReductionAmount(
          selectedOrderItem,
          quantityToBeKept,
        );
      } else {
        if (selectedCartItem?.modifier && selectedCartItem?.modifierGroup) {
          const selectedModifier = selectedOrderItem.modifiers.find(
            (x: OrderItemModifier) => x.id === selectedCartItem.modifier,
          );

          const isItemValid = isOrderItemValidAfterModifierDeletion(
            selectedOrderItem as OrderItem,
            selectedCartItem,
          );

          if (isItemValid) {
            reductionAmount = selectedModifier?.unitPrice || 0;
          } else {
            reductionAmount = computeOrderItemValue(selectedOrderItem);
          }
        } else {
          reductionAmount = calculateReductionAmount(
            selectedOrderItem,
            quantityToBeKept,
          );
        }
      }

      const totalPaymentAmount = order?.totalPaymentAmount || 0;
      const paidAmount = calculateTotalPaidAmount(order?.payments || []);
      return totalPaymentAmount - reductionAmount >= paidAmount;
    },
    [
      orderItems,
      selectedCartItem,
      order?.totalPaymentAmount,
      order?.payments,
      calculateReductionAmount,
      isOrderItemValidAfterModifierDeletion,
    ],
  );

  const onPressDangerAction = useCallback(
    async (quantityToBeKept?: number) => {
      if (selectedCartItem) {
        if (!isValidTotalPriceBeforeRemoveItem(quantityToBeKept)) {
          showNotification({
            message: translate('payment.amountCannotBeLessThanRemaining'),
            error: true,
          });
          unselectCartItem();
          return;
        }
        const orderItemsCopy = cloneJSON(orderItems);
        const orderItem = orderItemsCopy.find(
          (orderItem: OrderItem) => orderItem.id === selectedCartItem.item,
        );
        //if selected item is a modifier, we check for qty and decrement by 1, if its only 1, then we remove it
        if (selectedCartItem?.modifier && selectedCartItem?.modifierGroup) {
          const modifierQuantity =
            orderItem.modifiers.find(
              (x: OrderItemModifier) => x.id === selectedCartItem.modifier,
            )?.quantity || 0;
          if (modifierQuantity > 1) {
            const quantity = modifierQuantity - 1;
            updateCart<UpdateModifierQuantityEvent>(
              OrderAction.ORDER_ITEM_UPDATE_MODIFIER_QUANTITY,
              {
                modifierId: selectedCartItem.modifier,
                orderItemId: selectedCartItem.item,
                quantity: quantity,
                modifierGroupId: selectedCartItem.modifierGroup,
              },
            );
          } else {
            const isItemValid = isOrderItemValidAfterModifierDeletion(
              orderItem as OrderItem,
              selectedCartItem,
            );

            if (isItemValid) {
              updateCart<RemoveModifierEvent>(
                OrderAction.ORDER_ITEM_REMOVE_MODIFIER,
                {
                  modifierGroupId: selectedCartItem?.modifierGroup || '',
                  modifierId: selectedCartItem?.modifier,
                  orderItemId: selectedCartItem?.item,
                },
              );
            } else {
              showNotification({
                error: true,
                message: translate('order.orderItemRemoved', {
                  name: orderItem?.product?.name,
                }),
              });
              const isBeingTracked = isInventoryBeingTracked(
                allProducts[orderItem?.product.id].storesInventory,
                currentStoreId,
              );

              if (Boolean(computeRestrictVoidItem(order, orderItem))) {
                showNotification({
                  error: true,
                  message: translate('order.voidPartiallyPaid'),
                });
                return;
              }
              updateCart<RemoveOrderItemEvent>(OrderAction.ORDER_ITEM_REMOVE, {
                orderItemId: orderItem?.id as string,
                quantity: -orderItem?.quantity as number,
                inventoryTracked: isBeingTracked,
                productId: orderItem?.product.id as string,
                ...(orderItem?.comboItems?.length && {
                  isCombo: true,
                }),
              });

              orderItem?.status !== OrderItemStatus.CANCELLED &&
                onChangeProductQuantity(
                  orderItem?.product.id as string,
                  -orderItem?.quantity as number,
                );
            }
          }
        } else if (selectedCartItem.selectedItemKeyForAdjustment) {
          //Remove selected adjustment from item
          updateCart<RemoveOrderAdjustments>(
            OrderAction.ORDER_REMOVE_ADJUSTMENTS,
            {
              orderItemIds: [selectedCartItem.selectedItemKeyForAdjustment],
            },
          );
          selectedItemAdjustment.current =
            selectedCartItem.selectedItemKeyForAdjustment;
        } else {
          if (
            orderItem?.status === OrderItemStatus.CREATED ||
            orderItem?.status === OrderItemStatus.ON_HOLD
          ) {
            if (quantityToBeKept) onConfirmSetQuantity(quantityToBeKept);
            else {
              const isBeingTracked = isInventoryBeingTracked(
                allProducts[orderItem?.product.id as string].storesInventory,
                currentStoreId,
              );
              if (Boolean(computeRestrictVoidItem(order, orderItem))) {
                showNotification({
                  error: true,
                  message: translate('order.voidPartiallyPaid'),
                });
                return;
              }
              updateCart<RemoveOrderItemEvent>(OrderAction.ORDER_ITEM_REMOVE, {
                orderItemId: selectedCartItem.item,
                quantity: -orderItem?.quantity as number,
                inventoryTracked: isBeingTracked,
                productId: orderItem?.product.id as string,
                ...(orderItem?.comboItems?.length && {
                  isCombo: true,
                }),
              });
              orderItem?.status !== OrderItemStatus.CANCELLED &&
                onChangeProductQuantity(
                  orderItem?.product.id as string,
                  -orderItem?.quantity as number,
                );
            }
          }
        }
        unselectCartItem();
      } else if (selectedRewardItem) {
        updateCart<RemoveRewardItemEvent>(OrderAction.ORDER_REWARD_REMOVE, {
          rewardId: selectedRewardItem.reward,
          productId: selectedRewardItem.productId,
        });
        selectRewardItem(undefined);
      } else if (
        order?.status &&
        [OrderStatus.CREATED, OrderStatus.ON_HOLD].includes(order?.status)
      ) {
        showModal(
          <ConfirmationModal
            onConfirm={clearCart}
            title={translate('order.clearCart')}
            message={translate('order.clearCartDesc')}
            confirmLabel={translate('order.clearCart')}
          />,
        );
      } else if (order?.status === OrderStatus.IN_PROGRESS) {
        discardChanges();
        resetBackToOriginalQuantities();
        await closeOrderCart({ status: OrderStatus.IN_PROGRESS });
        await openOrderCart(order.id);
      }
    },
    [
      selectedCartItem,
      selectedRewardItem,
      order,
      isValidTotalPriceBeforeRemoveItem,
      orderItems,
      unselectCartItem,
      showNotification,
      translate,
      updateCart,
      isOrderItemValidAfterModifierDeletion,
      allProducts,
      currentStoreId,
      onChangeProductQuantity,
      onConfirmSetQuantity,
      showModal,
      clearCart,
      discardChanges,
      resetBackToOriginalQuantities,
      closeOrderCart,
      openOrderCart,
    ],
  );

  const onConfirmUpdateNotes = useCallback(
    (notes: string) => {
      if (selectedCartItem) {
        updateCart<AddOrderItemNotesEvent>(OrderAction.ORDER_ITEM_ADD_NOTES, {
          note: notes,
          orderItemId: selectedCartItem.item,
        });
        unselectCartItem();
      } else {
        updateCart<UpdateOrderNotesEvent>(
          OrderAction.ORDER_UPDATE_ORDER_NOTES,
          {
            notes,
          },
        );
      }
    },
    [selectedCartItem, updateCart, unselectCartItem],
  );

  const onSubmitVoucher = useCallback(
    (voucher: Voucher) => {
      if (!order) return;
      if (voucher.redeemed) {
        showNotification({
          message: translate('vouchers.voucherAlreadyApplied'),
          error: true,
        });
        return;
      }
      if (isVoucherAppliedOnOrder(order, voucher.id)) {
        showNotification({
          message: translate('vouchers.voucherAlreadyApplied'),
          error: true,
        });
        return;
      }

      const result = generateAdjustmentFromVoucher(order, voucher);
      if (result.productError) {
        showNotification({
          message: translate('vouchers.noEligibleProduct'),
          error: true,
        });
      }

      result.orderAdjustments.forEach(adjustment => {
        updateCart(OrderAction.ORDER_UPDATE_ADJUSTMENTS, {
          adjustments: [...(order?.adjustments ?? []), adjustment],
        });
      });

      result.orderItemAdjustments.forEach(adjustment => {
        updateCart(OrderAction.ORDER_ADD_ADJUSTMENT, {
          adjustment,
          productId: result.productId || '',
        });
      });
    },
    [order, updateCart, showNotification, translate],
  );

  const onAddVoucher = useCallback(() => {
    showModal(
      <AddVoucherModal
        onSubmit={onSubmitVoucher}
        onClose={autoSaveOrder.current ? saveOrderInvocation : undefined}
      />,
    );
  }, [showModal, onSubmitVoucher, saveOrderInvocation]);

  const onSubmitNotes = useCallback(
    (notes: string) => {
      onConfirmUpdateNotes(notes);
      if (autoSaveOrder.current) saveOrderInvocation();
    },
    [onConfirmUpdateNotes, saveOrderInvocation],
  );

  const onPressUpdateOrderNotes = useCallback(() => {
    const selectedItem =
      selectedCartItem &&
      order?.orderItems.find(x => x.id === selectedCartItem.item);

    escapeDiscardModal.current = false;

    if (selectedItem) {
      showModal(
        <AddNoteModal
          value={selectedItem.notes}
          onSubmit={onConfirmUpdateNotes}
        />,
      );
    } else {
      showModal(
        <AddNoteModal
          value={order?.orderNote}
          onSubmit={onSubmitNotes}
          onClose={autoSaveOrder.current ? saveOrderInvocation : undefined}
        />,
      );
    }
  }, [
    selectedCartItem,
    order?.orderItems,
    order?.orderNote,
    showModal,
    onConfirmUpdateNotes,
    onSubmitNotes,
    saveOrderInvocation,
  ]);

  const onPressTransferItems = useCallback(() => {
    const hasAccess = canI([{ onResource: Resource.TRANSFER_ORDER }], {
      prompt: true,
    });
    if (!hasAccess) return;
    if (isDirty || order?.status !== OrderStatus.IN_PROGRESS) {
      showNotification({
        error: true,
        message: translate('order.cannotTransferUnsavedOrder'),
      });
    } else setIsTransferItemsVisible(true);
  }, [canI, isDirty, order?.status, showNotification, translate]);

  const onCloseTransferItems = useCallback(() => {
    setIsTransferItemsVisible(false);
  }, []);

  const onConfirmUpdateName = useCallback(
    (orderName: string) => {
      updateCart<AssignOrderNameEvent>(OrderAction.ORDER_ASSIGN_NAME, {
        orderName,
      });
    },
    [updateCart],
  );

  useEffect(() => {
    if (isTransferItemsEnabled && !previousTransferItem && transferItem) {
      onPressTransferItems();
    }
  }, [
    isTableManagementEnabled,
    isTransferItemsEnabled,
    onPressTransferItems,
    previousTransferItem,
    transferItem,
  ]);

  const onSubmitName = useCallback(
    (name: string, callback?: () => void) => {
      onConfirmUpdateName(name);
      if (callback) callback();
    },
    [onConfirmUpdateName],
  );

  const updateOrderName = useCallback(
    (callback?: () => void) => {
      escapeDiscardModal.current = false;
      if (!!order?.orderName) {
        callback && callback();
        return;
      }
      showModal(
        <AddNoteModal
          title={translate('modal.addOrderName')}
          maxLength={25}
          value={order?.orderName}
          onSubmit={name => onSubmitName(name, callback)}
          onClose={
            callback
              ? callback
              : autoSaveOrder.current
              ? saveOrderInvocation
              : undefined
          }
        />,
      );
    },
    [showModal, order?.orderName, onSubmitName, saveOrderInvocation, translate],
  );

  const onConfirmOrderType = useCallback(
    (orderTypeId: string) => {
      const newOrderType = orderTypes?.find(o => o.id === orderTypeId);
      if (newOrderType?.code != OrderTypeCode.DINE_IN && order?.table) {
        updateCart<OrderEvent>(OrderAction.ORDER_UNASSIGN_TABLE);
      }
      closeModal();
      updateCart<AssignOrderTypeEvent>(OrderAction.ORDER_ASSIGN_ORDER_TYPE, {
        orderTypeId,
        orderTypeName: newOrderType?.name as string,
      });
    },
    [orderTypes, order?.table, closeModal, updateCart],
  );

  const onPressChangeOrderType = useCallback(() => {
    showModal(
      <PickerModal
        onSelect={onConfirmOrderType}
        title={translate('order.changeOrderType')}
        options={(orderTypes || []).map(x => ({
          label: x.name,
          value: x.id,
        }))}
      />,
    );
  }, [showModal, orderTypes, translate, onConfirmOrderType]);

  const getSelectedModiferInCart = useCallback(
    (selectedCartItem: CartSelectionState, parentItem: OrderItem) => {
      const selectedMod = parentItem?.modifiers?.find(
        x =>
          x.modifierGroupId === selectedCartItem.modifierGroup &&
          x.id === selectedCartItem.modifier &&
          x,
      );
      return selectedMod;
    },
    [],
  );

  const onPressUpdateQuantity = useCallback(() => {
    let item = orderItems.find(x => x.id === selectedCartItem?.item);
    if (selectedCartItem?.modifier) {
      const selectedMod = getSelectedModiferInCart(
        selectedCartItem,
        item as OrderItem,
      );

      item = {
        quantity: selectedMod?.quantity,
        id: selectedMod?.id,
        unitPrice: selectedMod?.unitPrice,
        product: {
          id: selectedMod?.id,
          name: selectedMod?.name,
        } as unknown as ProductAlias,
        status: item?.status as OrderItemStatus,
      } as OrderItem;
    }

    if (item) {
      if (item?.product?.variableQuantity) {
        const defaultSize = item.product?.measuredBy?.defaultSize || 1;
        const onSubmit = (updatedSize: number) => {
          const updatedQuantity = updatedSize / defaultSize;
          onConfirmSetQuantity(updatedQuantity);
        };
        showModal(
          <VariableQuantityModal
            item={item.product}
            quantity={item.quantity}
            onSubmit={onSubmit}
            unitPrice={item.unitPrice}
          />,
        );
      } else {
        showModal(
          <SetQuantityModal item={item} onSubmit={onConfirmSetQuantity} />,
        );
      }
    }
  }, [
    selectedCartItem,
    orderItems,
    showModal,
    onConfirmSetQuantity,
    getSelectedModiferInCart,
  ]);

  const getComboProductPricing = useCallback(
    (comboItems: Array<Partial<OrderItem>>) => {
      const products = comboItems
        .map(p =>
          allProducts[p.product?.id as string]
            ? allProducts[p.product?.id as string]
            : { ...p.product, pricings: p.pricing },
        )
        .filter(p => p) as Product[];
      const productMap = keyBy(products, 'id');
      const productPricing = products.reduce(
        (initialValue, p) => ({
          ...initialValue,
          [p.id]: getBestPrice(p, {
            orderType: selectedOrderType,
            venue: session.currentVenue?.id,
            store: session.currentStore?.id,
            pricingGroupId: pricingGroup,
          }),
        }),
        {} as Record<string, ProductPricing>,
      );
      return { productMap, productPricing };
    },
    [
      allProducts,
      pricingGroup,
      selectedOrderType,
      session.currentStore?.id,
      session.currentVenue?.id,
    ],
  );

  const onConfirmSetPrice = useCallback(
    (unitPrice: number, adjustments: Adjustment[]) => {
      const item = orderItems.find(i => i.id === selectedCartItem?.item);
      const { productPricing } = getComboProductPricing(item?.comboItems || []);
      const originalProductPricing = Object.keys(productPricing).reduce(
        (list, productId) => {
          list.push({
            productId,
            amount: productPricing[productId].sellingPrice.amount,
          });
          return list;
        },
        [] as OriginalProductPricing[],
      );

      if (selectedCartItem) {
        updateCart<UpdateOrderItemPriceEvent>(
          OrderAction.ORDER_ITEM_UPDATE_PRICE,
          {
            unitPrice,
            adjustments,
            orderItemId: selectedCartItem?.item || '',
            ...(originalProductPricing.length && { originalProductPricing }),
          },
        );
        unselectCartItem();
      } else {
        updateCart(OrderAction.ORDER_ADD_ADJUSTMENT, {
          adjustment: adjustments[0],
        });
      }
    },
    [
      orderItems,
      getComboProductPricing,
      selectedCartItem,
      updateCart,
      unselectCartItem,
    ],
  );

  const onPressUpdatePrice = useCallback(() => {
    const allowAdjustment = canI([{ onResource: Resource.ALLOW_ADJUSTMENTS }], {
      prompt: true,
    });
    if (!allowAdjustment) return;
    const item =
      selectedCartItem && orderItems.find(x => x.id === selectedCartItem?.item);

    if (item) {
      const product = allProducts[item.product.id];
      const pricing = getBestPrice(product as ProductAlias, {
        orderType: selectedOrderType,
        venue: session.currentVenue?.id,
        store: session.currentStore?.id,
      });

      showModal(
        <SetPriceModal
          item={item}
          onSubmit={onConfirmSetPrice}
          defaultPrice={pricing?.sellingPrice?.amount || 0}
          minSellingPrice={product.minSellingPrice}
          maxSellingPrice={product.maxSellingPrice}
          keypadInput={
            advancedKeypadValue ? advancedKeypadValue.toString() : ''
          }
        />,
      );
    } else {
      showModal(
        <SetPriceModal
          item={
            {
              unitPrice: order?.subTotal || 0,
              quantity: 1,
              discounts: order?.discounts || [],
            } as OrderItem
          }
          onSubmit={onConfirmSetPrice}
          maxDiscounts={1}
          priceOverrideMode="discount"
          defaultPrice={order?.subTotal || 0}
          title={translate('order.setOrderPrice')}
          keypadInput={
            advancedKeypadValue ? advancedKeypadValue.toString() : ''
          }
        />,
        {
          onBackdropPress: closeModal,
        },
      );
    }
  }, [
    canI,
    selectedCartItem,
    orderItems,
    allProducts,
    selectedOrderType,
    session.currentVenue?.id,
    session.currentStore?.id,
    showModal,
    onConfirmSetPrice,
    advancedKeypadValue,
    order?.subTotal,
    order?.discounts,
    translate,
    closeModal,
  ]);

  const onTableNumberChange = useCallback(
    (tableNumber: string, guestCount: number) => {
      escapeDiscardModal.current = false;
      updateCart(OrderAction.ORDER_ASSIGN_TABLE, {
        tableId: tableNumber,
        tableName: tableNumber,
        guestCount,
      });
    },
    [updateCart],
  );

  const navigateToOrder = useCallback(
    (orderId: string) => {
      // This is to escape the modal
      escapeDiscardModal.current = true;
      navigation.setParams({
        id: orderId,
        isCompleted: false,
        isExisting: true,
      });
      setCartInitialized(false);
    },
    [navigation],
  );

  const askConfirmationForTableTransfer = useCallback(
    (callback: () => void) => {
      if (
        order?.status &&
        [OrderStatus.CREATED, OrderStatus.ON_HOLD].includes(order?.status)
      ) {
        callback();
      } else if (itemsChanged) {
        showNotification({
          error: true,
          message: translate('order.notificationOnModifiedOrderTableChange'),
        });
      } else {
        showModal(
          <ConfirmationModal
            title={translate('order.confirmTableTransfer')}
            message={translate('order.ConfirmTableTransferMessage')}
            onConfirm={callback}
          />,
        );
      }
    },
    [order?.status, itemsChanged, showNotification, translate, showModal],
  );

  const submitTableNumber = useCallback(
    (tableNumber: string, guestCount: number, callback?: Function) => {
      if (!+tableNumber.split('-')[1]) {
        return showNotification({
          error: true,
          message: translate('order.invalidTableNumber'),
        });
      }
      const existingOrder = dineInOrdersInProgress().find(
        order => order.table?.name === tableNumber,
      );

      if (
        existingOrder &&
        existingOrder.id !== order?.id &&
        ((order?.status === OrderStatus.CREATED && order?.orderItems.length) ||
          autoSaveOrder.current ||
          autoPayOrder.current ||
          quickPayFunctionMaps.current)
      ) {
        return showNotification({
          error: true,
          message: translate('order.existingTableNumberError'),
        });
      } else if (
        existingOrder &&
        existingOrder.id !== order?.id &&
        !(
          autoSaveOrder.current ||
          autoPayOrder.current ||
          quickPayFunctionMaps.current
        )
      ) {
        navigateToOrder(existingOrder.id);
        closeModal();
      } else {
        if (
          order?.table?.name !== tableNumber ||
          order?.table?.guestCount !== guestCount
        ) {
          const updateTableNumber = () => {
            onTableNumberChange(
              tableNumber,
              guestCount || order?.table?.guestCount || 1,
            );
            closeModal();
          };
          askConfirmationForTableTransfer(updateTableNumber);
        }
      }
      if (autoSaveOrder.current) {
        saveOrderInvocation();
        closeModal();
      }
      if (autoPayOrder.current) {
        setProceedPayOrder(true);
        closeModal();
      }

      if (quickPayFunctionMaps.current && callback) {
        closeModal();
        callback();
      }
    },
    [
      dineInOrdersInProgress,
      order,
      closeModal,
      showNotification,
      translate,
      navigateToOrder,
      askConfirmationForTableTransfer,
      onTableNumberChange,
      saveOrderInvocation,
    ],
  );

  const clearAutoSaveOrder = useCallback(() => {
    autoSaveOrder.current = false;
  }, []);

  const onSelectOrderItemsToTransfer = useCallback(
    (sourceOrderItems: TransferItem[]) => {
      const onSelection = async (
        targetTable: Table,
        targetOrderId?: string,
      ) => {
        setIsTransferItemsVisible(false);
        if (
          !canPerformTransfer({
            sourceOrderItems: sourceOrderItems.map(item => ({
              ...item,
              tableId: item.table?.id,
            })),
            targetTableId: targetTable.id,
          })
        ) {
          return showNotification({
            message: translate('order.cannotTransferBackToSource'),
            error: true,
          });
        }

        // updatedOrders contain both source and target orders
        const handlePostTransfer = (updatedOrders: Order[] = []) => {
          const updatedOrder = updatedOrders.find(
            updatedOrder => updatedOrder.id === order?.id,
          );
          if (updatedOrder) {
            globalOrderStore.setState(prev => ({
              ...prev,
              currentState: updatedOrder,
              originalState: updatedOrder,
              pendingEvents: [],
              isDirty: false,
            }));
          }
          showModal(
            <TransferSuccess
              items={sourceOrderItems.flatMap(source => source.orderItems)}
              sourceTable={order?.table?.id}
              targetTable={targetTable?.id}
              onClose={onClose}
            />,
          );
        };

        await transferOrderItems({
          targetOrderId,
          targetTable,
          sourceOrderItems,
          postTransfer: handlePostTransfer,
          location: POSScreens.NEW_ORDER,
        });
      };
      const onClose = () => {
        clearAutoSaveOrder();
        closeModal();
      };
      showModal(
        <SelectTableModal
          onSelection={onSelection}
          onClose={onClose}
          canSelectSubTable
        />,
      );
    },
    [
      showModal,
      transferOrderItems,
      showNotification,
      translate,
      order?.table?.id,
      order?.id,
      clearAutoSaveOrder,
      closeModal,
    ],
  );

  const openTableNumberModal = useCallback(
    (callback?: () => void) => {
      if (callback) quickPayFunctionMaps.current = true;
      const onSubmitTableNumberModal = (
        tableNumber: string,
        guestCount: number,
      ) => submitTableNumber(tableNumber, guestCount, callback);

      showModal(
        <SetTableNameModal
          order={order}
          onSubmit={onSubmitTableNumberModal}
          showGuestCounter={
            !!(session?.deviceProfile as DeviceProfile)?.promptGuestCount
          }
          onCancel={clearAutoSaveOrder}
        />,
      );
    },
    [
      showModal,
      order,
      session?.deviceProfile,
      clearAutoSaveOrder,
      submitTableNumber,
    ],
  );

  const LegacyOpenSectionSelectModal = useCallback(
    (
      _: SetOrderTableChainState,
      onSelect: (id: string) => void,
      onBack: () => void,
    ) => {
      const onSelectSection = (sectionId: string) => {
        const availableTable = getAvailableTablesBySection(sectionId);
        if (availableTable.length === 0) {
          showNotification({
            error: true,
            message: translate('order.noAvailableTableForSection'),
          });
        } else {
          onSelect(sectionId);
        }
      };
      const onClose = () => {
        clearAutoSaveOrder();
        onBack && onBack();
      };
      showModal(
        <SectionsSelectModal
          onSelectSection={onSelectSection}
          onClose={onClose}
        />,
      );
    },
    [
      showModal,
      getAvailableTablesBySection,
      showNotification,
      translate,
      clearAutoSaveOrder,
    ],
  );

  const LegacyOpenTableSelectModal = useCallback(
    (
      { sectionId }: SetOrderTableChainState,
      onSelect: (id: string) => void,
      onBack: () => void,
    ) => {
      const availableTable = getAvailableTablesBySection(sectionId as string);
      const onCancel = () => {
        clearAutoSaveOrder();
        onBack();
      };
      showModal(
        <PickerModal
          onSelect={onSelect}
          title={translate('order.selectTable')}
          options={sortTablesByName(availableTable).map(x => ({
            label: x.name,
            value: x.id,
          }))}
          closeBtn={
            <IconButton
              icon="arrow-left"
              containerStyle={styles.btnClose}
              onPress={onCancel}
            />
          }
        />,
      );
    },
    [getAvailableTablesBySection, showModal, translate, clearAutoSaveOrder],
  );

  const openTableSelectModal = useCallback(
    (
      _: SetOrderTableChainState,
      onSelect: (sectionId: string, tableId: string) => void,
      onBack: () => void,
    ) => {
      const onSelection = (table: Table, orderId?: string) => {
        if (!!orderId) {
          showNotification({
            error: true,
            message: translate('order.cannotSelectTable'),
          });
        } else {
          onSelect(table.section.id, table.id);
        }
      };
      const onClose = () => {
        clearAutoSaveOrder();
        onBack && onBack();
      };
      showModal(
        <SelectTableModal onSelection={onSelection} onClose={onClose} />,
      );
    },
    [showModal, showNotification, translate, clearAutoSaveOrder],
  );

  const openSetGuestModal = useCallback(
    (
      _: SetOrderTableChainState,
      onSelect: (g: number) => void,
      onBack: () => void,
    ) => {
      showModal(<SetGuestModal onSubmit={onSelect} closeModal={onBack} />);
    },
    [showModal],
  );

  const onFinishSelectTableAndGuest = useCallback(
    (state: SetOrderTableChainState) => {
      guestCountRef.current = state.guest ?? 0;
      const section = session.deviceProfile?.sections?.find(
        x => x.id === state?.sectionId,
      );
      const table = section?.tables.find(x => x.id === state?.tableId);
      updateCart<AssignTableEvent>(OrderAction.ORDER_ASSIGN_TABLE, {
        tableId: state.tableId,
        tableName: table?.name as string,
        guestCount: state.guest,
        sectionId: section?.id as string,
        sectionName: section?.name as string,
      });
      closeModal();
      onFinishSetTable.current && onFinishSetTable.current();
      willProceedPayOrderRef.current && setProceedPayOrder(true);
      onFinishSetTable.current = undefined;
    },
    [closeModal, updateCart, session],
  );

  const openTableModalChain = useChainModal<SetOrderTableChainState>(
    useMemo(() => {
      let steps;
      if (isTransferItemsEnabled) {
        steps = [
          {
            action: openTableSelectModal,
            transformSubmitParamToState: (
              sectionId: string,
              tableId: string,
            ) => ({ sectionId, tableId }),
          },
          {
            action: openSetGuestModal,
            transformSubmitParamToState: (count: number) => ({ guest: count }),
          },
        ];
      } else {
        steps = [
          {
            action: LegacyOpenSectionSelectModal,
            transformSubmitParamToState: (id: string) => ({ sectionId: id }),
          },
          {
            action: LegacyOpenTableSelectModal,
            transformSubmitParamToState: (id: string) => ({ tableId: id }),
          },
          {
            action: openSetGuestModal,
            transformSubmitParamToState: (count: number) => ({ guest: count }),
          },
        ];
      }
      if (isTransferItemsEnabled) {
        if (!(session?.deviceProfile as DeviceProfile)?.promptGuestCount) {
          // if promptGuestCount is false, will not prompt for guest count
          return steps.slice(0, 1);
        }
        // else will prompt for select table, & set guest count
        return steps;
      } else {
        if (
          // if section length is 1 & promptGuestCount is false, will not prompt for select section & guest count
          (session?.deviceProfile as DeviceProfile)?.sections?.length === 1 &&
          !(session?.deviceProfile as DeviceProfile)?.promptGuestCount
        ) {
          return steps.slice(1, 2);
        } else if (
          !(session?.deviceProfile as DeviceProfile)?.promptGuestCount
        ) {
          return steps.slice(0, 2);
        } else if (
          (session?.deviceProfile as DeviceProfile)?.sections?.length === 1
        ) {
          // if section length is 1, will not prompt for select section
          return steps.slice(1, 3);
        }
        // else will prompt for select table, select section & set guest count
        return steps;
      }
    }, [
      isTransferItemsEnabled,
      LegacyOpenSectionSelectModal,
      LegacyOpenTableSelectModal,
      openSetGuestModal,
      openTableSelectModal,
      session?.deviceProfile,
    ]),
    onFinishSelectTableAndGuest,
    useMemo(
      () =>
        (session?.deviceProfile as DeviceProfile)?.sections?.length === 1
          ? {
              sectionId: (session?.deviceProfile as DeviceProfile)?.sections[0]
                ?.id,
            }
          : {},
      [session?.deviceProfile],
    ),
  );

  const setOrderTypeToDineIn = useCallback(() => {
    const dineInOrderType = orderTypes?.find(
      orderType => orderType.code === OrderTypeCode.DINE_IN,
    );
    if (dineInOrderType) {
      if (orderType?.code !== OrderTypeCode.DINE_IN) {
        updateCart<AssignOrderTypeEvent>(OrderAction.ORDER_ASSIGN_ORDER_TYPE, {
          orderTypeId: dineInOrderType.id,
          orderTypeName: dineInOrderType.name,
        });
      }
    }
  }, [orderTypes, orderType?.code, updateCart]);

  const getExistingOrderByAdvanceKeyPad = useCallback(
    (advancedKeypadValue: number) => {
      const tableNumberWithPerfix = session.deviceProfile?.sections?.map(
        x => x.defaultPrefix + '-' + advancedKeypadValue,
      );
      // handle case table number without prefix
      tableNumberWithPerfix?.push(advancedKeypadValue.toString());
      const uniqueTableNumbers = [...new Set(tableNumberWithPerfix)];
      const inProgressOrderMatchKeypadValue: Order[] = [];

      uniqueTableNumbers.forEach(tableNumber =>
        dineInOrdersInProgress()
          .filter(order => order.table?.name === tableNumber)
          .forEach(order => inProgressOrderMatchKeypadValue.push(order)),
      );
      return inProgressOrderMatchKeypadValue;
    },
    [dineInOrdersInProgress, session.deviceProfile?.sections],
  );
  const onPressTableNumber = useCallback(() => {
    if (isTableManagementEnabled) {
      const existingOrders: Order[] =
        getExistingOrderByAdvanceKeyPad(advancedKeypadValue);
      if (advancedKeypadValue && existingOrders.length == 1) {
        navigateToOrder(existingOrders[0]?.id);
        setAdvancedKeypadValue(0);
        return;
      }

      if (advancedKeypadValue && existingOrders.length > 1) {
        navigation.navigate('FloorView');
        return;
      }

      if (
        order?.status &&
        ![OrderStatus.CREATED, OrderStatus.ON_HOLD].includes(order?.status)
      ) {
        escapeDiscardModal.current = false;
        navigation.navigate('FloorView');
        return;
      }
      setOrderTypeToDineIn();
      openTableModalChain();
    } else {
      setOrderTypeToDineIn();
      if (advancedKeypadValue) {
        const tableNumber = `T-${advancedKeypadValue}`;
        const existingOrder: Order =
          getExistingOrderByAdvanceKeyPad(advancedKeypadValue)[0];
        if (
          existingOrder &&
          existingOrder.id !== order?.id &&
          order?.status === OrderStatus.CREATED &&
          order?.orderItems.length
        ) {
          return showNotification({
            error: true,
            message: translate('order.existingTableNumberError'),
          });
        } else if (existingOrder) {
          navigateToOrder(existingOrder.id);
        } else {
          if (order?.table?.name !== tableNumber) {
            const updateTableNumber = () => {
              onTableNumberChange(tableNumber, order?.table?.guestCount || 1);
              closeModal();
            };
            askConfirmationForTableTransfer(updateTableNumber);
          }
        }
        setAdvancedKeypadValue(0);
      } else {
        openTableNumberModal();
      }
    }
  }, [
    isTableManagementEnabled,
    getExistingOrderByAdvanceKeyPad,
    advancedKeypadValue,
    order?.status,
    order?.id,
    order?.orderItems.length,
    order?.table?.name,
    order?.table?.guestCount,
    setOrderTypeToDineIn,
    openTableModalChain,
    navigateToOrder,
    navigation,
    showNotification,
    translate,
    askConfirmationForTableTransfer,
    onTableNumberChange,
    closeModal,
    openTableNumberModal,
  ]);

  const requestToSetTableOrder = useCallback(
    (callback?: () => void, willProceedPayOrder?: boolean) => {
      if (isTableManagementEnabled) {
        onFinishSetTable.current = callback;
        willProceedPayOrderRef.current = willProceedPayOrder;
        openTableModalChain();
      } else {
        openTableNumberModal(callback);
      }
    },
    [openTableModalChain, isTableManagementEnabled, openTableNumberModal],
  );

  const promptOrderName =
    session.deviceProfile?.orderIdentifier === OrderIdentifier.CUSTOMER_NAME &&
    (order?.status === OrderStatus.CREATED ||
      order?.status === OrderStatus.ON_HOLD);

  const getNextActionByOrderType = useCallback(
    (type: string) => {
      const orderNameFlow = () => {
        if (promptOrderName) {
          return order?.orderName ? undefined : updateOrderName;
        } else return undefined;
      };
      switch (type) {
        case OrderTypeCode.DINE_IN:
          return order?.table ? undefined : requestToSetTableOrder;
        case OrderTypeCode.DELIVERY:
          return order?.customer ? undefined : onClickCustomer;
        case OrderTypeCode.PICK_UP:
          return orderNameFlow();
        case OrderTypeCode.TAKEAWAY:
          return orderNameFlow();
        default:
          return orderNameFlow();
      }
    },
    [
      promptOrderName,
      order?.orderName,
      order?.table,
      order?.customer,
      updateOrderName,
      requestToSetTableOrder,
      onClickCustomer,
    ],
  );

  const onPressSave = useCallback(() => {
    analyticsService.capture('cart_button', {
      button: 'Send',
    });
    escapeDiscardModal.current = true;
    autoSaveOrder.current = true;
    const callDineInFlow =
      orderType?.code && getNextActionByOrderType(orderType?.code);
    unselectCartItem();
    callDineInFlow
      ? callDineInFlow(saveOrderInvocation)
      : saveOrderInvocation();
  }, [
    orderType?.code,
    getNextActionByOrderType,
    unselectCartItem,
    saveOrderInvocation,
  ]);

  const checkForUnfiredItems = useCallback(
    callback => {
      const comboItems = orderItems
        .map(i => i.comboItems ?? [])
        .flat() as OrderItem[];
      const pendingOrderItems = [...orderItems, ...comboItems].filter(
        item => item.status === OrderItemStatus.ON_HOLD,
      );

      const onPressFireItems = () => {
        callback();
      };

      if (order?.status === OrderStatus.ON_HOLD) {
        callback();
      } else if (pendingOrderItems.length) {
        showModal(<UnFiredItemsWarningModal onConfirm={onPressFireItems} />);
      } else {
        callback();
      }
    },
    [order, orderItems, showModal],
  );

  const onPressPay = useCallback(async () => {
    analyticsService.capture('cart_button', {
      button: 'Pay',
    });
    const allowPay = canI([{ onResource: Resource.FINISH_SALE }], {
      prompt: true,
    });
    const isPickUp =
      orderType?.code &&
      ![OrderTypeCode.DELIVERY, OrderTypeCode.DINE_IN].includes(
        orderType.code as OrderTypeCode,
      );

    if (allowPay) {
      checkForUnfiredItems(() => {
        autoPayOrder.current = true;
        if (orderType?.code === OrderTypeCode.DINE_IN && !order?.table) {
          requestToSetTableOrder(undefined, true);
        } else if (promptOrderName && isPickUp) {
          updateOrderName(payOrder);
        } else payOrder();
      });
    }
  }, [
    canI,
    checkForUnfiredItems,
    order?.table,
    orderType?.code,
    payOrder,
    promptOrderName,
    requestToSetTableOrder,
    updateOrderName,
  ]);

  const openOrderAwayNavigation = useCallback(
    (
      e?: EventArg<
        'beforeRemove',
        true,
        {
          action: NavigationAction;
        }
      >,
      callback?: Function,
    ) => {
      if (!isDirty) {
        // If we don't have unsaved changes, then we don't need to do anything
        return;
      }

      if (
        order &&
        order.status !== OrderStatus.IN_PROGRESS &&
        order.status !== OrderStatus.ON_HOLD &&
        order?.orderItems.length === 0
      ) {
        // If we don't have unsaved changes, then we don't need to do anything
        return;
      }

      if (
        order &&
        order.status === OrderStatus.ON_HOLD &&
        order?.orderItems.length === 0
      ) {
        // if there are no items in on hold order then we do not need to do anything
        return;
      }

      if (order && order.status === OrderStatus.COMPLETED) {
        // If we have completed order, then we don't need to do anything
        return;
      }

      if (escapeDiscardModal.current) {
        // If we clicked on save changes, then we don't need to do anything
        return;
      }

      // Prevent default behavior of leaving the screen
      e && e.preventDefault();
      // Prompt the user before leaving the screen

      const saveOrderAndDispatch = async () => {
        await saveOrderInvocation(callback);
        e && navigation.dispatch(e.data.action);
      };

      const onPressSave = () => {
        const isPickUp =
          orderType?.code &&
          ![OrderTypeCode.DELIVERY, OrderTypeCode.DINE_IN].includes(
            orderType.code as OrderTypeCode,
          );

        if (orderType?.code === OrderTypeCode.DINE_IN && !order?.table) {
          requestToSetTableOrder(saveOrderAndDispatch);
        } else if (promptOrderName && isPickUp) {
          updateOrderName(saveOrderAndDispatch);
        } else {
          saveOrderAndDispatch();
        }
      };

      const discardChangesAndDispatch = () => {
        discardChanges();
        resetBackToOriginalQuantities();
        closeOrderCart();
        e && navigation.dispatch(e.data.action);
        callback?.();
      };
      const saveOrderThroughDiscardChanges = () => {
        escapeDefaultScreenNavigation.current = true;
        closeModal();
        onPressSave();
      };
      showModal(
        <ConfirmationModal
          type="focus"
          title={translate('order.discardChangeTitle')}
          message={translate('order.discardChangeDescription')}
          onConfirm={saveOrderThroughDiscardChanges}
          confirmLabel={translate('button.save')}
          onCancel={discardChangesAndDispatch}
          cancelLabel={translate('button.discard')}
        />,
      );
    },
    [
      isDirty,
      order,
      showModal,
      translate,
      saveOrderInvocation,
      navigation,
      orderType?.code,
      promptOrderName,
      requestToSetTableOrder,
      updateOrderName,
      discardChanges,
      resetBackToOriginalQuantities,
      closeOrderCart,
      closeModal,
    ],
  );

  useEffect(() => {
    const beforeRemoveSub = navigation.addListener(
      'beforeRemove',
      openOrderAwayNavigation,
    );
    const blurSub = navigation.addListener('blur', () =>
      openOrderAwayNavigation(),
    );
    return () => {
      beforeRemoveSub();
      blurSub();
    };
  }, [navigation, openOrderAwayNavigation]);

  const isOnHoldOrder = useMemo(
    () => order?.status == OrderStatus.ON_HOLD,
    [order?.status],
  );

  useEffect(() => {
    if (isOnHoldOrder || !isFocused || itemsChanged) return;
    if (orderItems.length && courses.length) {
      const orderItemsToFire: OrderItemFireStatus[] = [];
      orderItems.forEach(item => {
        if (
          (isCoursesEnabled && item.comboItems?.length) ||
          (item.status !== OrderItemStatus.CREATED &&
            item.status !== OrderItemStatus.ON_HOLD)
        )
          return;

        const shouldFire = isItemReadyToFire(item.course?.id);
        const expectedStatus = shouldFire
          ? OrderItemStatus.CREATED
          : OrderItemStatus.ON_HOLD;
        if (expectedStatus !== item.status)
          orderItemsToFire.push({
            id: item.id,
            status: shouldFire,
          });
      });
      fireOrderItemsHandler(orderItemsToFire);
    }
  }, [
    courses.length,
    isFocused,
    isItemReadyToFire,
    orderItems,
    fireOrderItemsHandler,
    itemsChanged,
    isOnHoldOrder,
    isCoursesEnabled,
  ]);

  const handleSelectProduct = useCallback(
    (selectedProduct: Product) => {
      addProductToCart(selectedProduct);
    },
    [addProductToCart],
  );

  const onPressPrintReceipt = useCallback(async () => {
    if (order) {
      // For unsaved order items the product name is not available within the order object
      // So grabbing the product name from unsaved order item object
      updateCart(OrderAction.ORDER_PRINT);

      const result = await printBill({
        ...order,
        orderItems: order.orderItems.map(eachItem => {
          if (!eachItem.product.name) {
            const unsavedItemName = orderItems.find(
              unsavedOrderItem => unsavedOrderItem.id === eachItem.id,
            )?.product.name;
            if (unsavedItemName) {
              return {
                ...eachItem,
                product: {
                  ...eachItem.product,
                  name: unsavedItemName,
                },
              };
            }
          }
          return eachItem;
        }),
      });
      if (result && Object.keys(result)?.length > 0 && result.error) {
        showNotification(result);
      }
    }
  }, [order, updateCart, printBill, orderItems, showNotification]);

  const newOrderButtonOnSaveDiscardModal = useCallback(
    (callback?: Function) => {
      openOrderAwayNavigation(undefined, callback);
    },
    [openOrderAwayNavigation],
  );

  const handleSwitchCourseItem = useCallback(
    (orderItemId: string, courseId: string, courseName?: string) => {
      unselectCartItem();
      updateCart<SwitchCourseItemEvent>(OrderAction.ORDER_SWITCH_COURSE_ITEM, {
        courseId: courseId === DEFAULT_ENTITY_ID ? undefined : courseId,
        courseName,
        orderItemId,
      });

      const shouldFireOrderItem =
        courses.find(course => course.id === courseId)?.autoFire || false;

      const orderItemsToFires = orderItems
        .filter(orderItem => orderItem.id === orderItemId)
        .map(item => ({
          id: item.id,
          status: shouldFireOrderItem,
        })) as OrderItemFireStatus[];
      fireOrderItemsHandler(orderItemsToFires);
    },
    [unselectCartItem, updateCart, courses, orderItems, fireOrderItemsHandler],
  );

  const readOnly = READ_ONLY_STATUSES.includes(
    order?.status || OrderStatus.CREATED,
  );
  const disableCartActions = useMemo(() => {
    return (
      (order?.orderItems.length || 0) === 0 &&
      (order?.adjustments?.filter(
        adj => adj.adjustmentType == AdjustmentType.REWARD,
      ).length || 0) === 0
    );
  }, [order?.orderItems, order?.adjustments]);
  const disableOrderActions =
    readOnly || disableCartActions || order?.orderItems.length === 0;
  const disableSaveAction =
    readOnly ||
    (order?.status !== OrderStatus.IN_PROGRESS &&
      order?.orderItems.length === 0);

  const selectedModifiersOfSelectedProduct = useMemo(() => {
    const modGroups: { [key: string]: string[] } = {};
    const selectedProductItem = order?.orderItems?.find(
      x => x.id === selectedCartItem?.item,
    );
    if (selectedProductItem?.modifiers) {
      selectedProductItem?.modifiers.forEach(x => {
        if (x.modifierGroupId && x.id && !modGroups[x.modifierGroupId]) {
          modGroups[x.modifierGroupId] = [x.id];
        } else if (x.modifierGroupId && x.id) {
          modGroups[x.modifierGroupId].push(x.id);
        }
      });
    }
    return modGroups;
  }, [order?.orderItems, selectedCartItem?.item]);

  const quantityOfSelectedItem = useCallback(
    (item: OrderItem) => {
      let quantity = item?.quantity || 0;
      if (selectedCartItem?.modifier) {
        // if selected a modifier item of product then, modifier quantity should be picked and incremented
        const selectedMod = getSelectedModiferInCart(
          selectedCartItem,
          item as OrderItem,
        );
        if (selectedMod) {
          quantity = selectedMod?.quantity || 0;
        }
      }
      return quantity;
    },
    [getSelectedModiferInCart, selectedCartItem],
  );

  // If item is part of combo then restrict cart action
  const isCartActionRestricted = useCallback(
    (item?: OrderItem) => {
      if (item?.parentCombo?.id) {
        showNotification({
          error: true,
          message: translate('order.comboItemActionErrorMessage'),
        });
        return true;
      }
      return false;
    },
    [translate, showNotification],
  );

  const onPressAction = useCallback(
    (action: string) => {
      const item = orderItems.find(x => x.id === selectedCartItem?.item);
      if (isCartActionRestricted(item)) return;
      const isVariableQuantity = item?.product?.variableQuantity || false;
      const defaultSize = item?.product?.measuredBy?.defaultSize || 1;
      const keypadValue = isVariableQuantity
        ? advancedKeypadValue / defaultSize
        : advancedKeypadValue;
      let quantity: number;
      switch (action) {
        case CartKeypadActions.CancelAction:
          if (advancedKeypadValue && selectedCartItem) {
            quantity = (item?.quantity || 0) - (advancedKeypadValue || 1);

            if (quantity >= 0) {
              quantity > 0
                ? onPressDangerAction(quantity)
                : onPressDangerAction();
            } else {
              showNotification({
                error: true,
                message: translate('order.cancelOrderItemError'),
              });
            }
          } else {
            onPressDangerAction();
          }
          break;
        case CartKeypadActions.UpdatePriceAction:
          onPressUpdatePrice();
          break;
        case CartKeypadActions.AddAction:
          quantity =
            quantityOfSelectedItem(item as OrderItem) + (keypadValue || 1);
          onConfirmSetQuantity(quantity);
          break;
        case CartKeypadActions.SubtractAction:
          quantity =
            quantityOfSelectedItem(item as OrderItem) - (keypadValue || 1);

          if (!isValidTotalPriceBeforeRemoveItem(Math.max(quantity, 0))) {
            showNotification({
              message: translate('payment.amountCannotBeLessThanRemaining'),
              error: true,
            });
            unselectCartItem();
            return;
          }

          if (
            item?.status === OrderItemStatus.CREATED ||
            item?.status === OrderItemStatus.ON_HOLD
          ) {
            if (quantity > 0) onConfirmSetQuantity(quantity);
            else {
              onPressAction(CartKeypadActions.CancelAction);
            }
          } else if (item?.status === OrderItemStatus.IN_PROGRESS) {
            const allowVoidItem = canI(
              [{ onResource: Resource.VOID_ORDER_ITEMS }],
              { prompt: true },
            );
            if (!allowVoidItem) return;
            if (Boolean(computeRestrictVoidItem(order, item))) {
              showNotification({
                error: true,
                message: translate('order.voidPartiallyPaid'),
              });
              return;
            }
            const updatedOrderItem = {
              ...item,
              quantity: advancedKeypadValue || 1,
            };
            showModal(
              <CancelOrderItemModalMap
                orderId={order?.id || ''}
                item={updatedOrderItem as unknown as OrderItem}
                onSubmit={(item: OrderItem, reason: VoidReason) => {
                  onConfirmSetQuantity(quantity, reason);
                }}
              />,
            );
          }
          break;
        default:
          break;
      }
    },
    [
      orderItems,
      advancedKeypadValue,
      selectedCartItem,
      onPressUpdatePrice,
      quantityOfSelectedItem,
      onConfirmSetQuantity,
      isValidTotalPriceBeforeRemoveItem,
      onPressDangerAction,
      showNotification,
      translate,
      unselectCartItem,
      canI,
      order,
      showModal,
      isCartActionRestricted,
    ],
  );

  const onPressSplitProductFromCart = useCallback(() => {
    if (advancedKeypadValue) {
      const item = orderItems.find(x => x.id === selectedCartItem?.item);
      const quantity = (item?.quantity || 0) - (advancedKeypadValue || 1);
      if (quantity > 0) {
        splitProductFromCart();
      } else {
        showNotification({
          error: true,
          message: translate('order.cancelSplitError'),
        });
      }
    } else {
      splitProductFromCart();
    }
  }, [
    selectedCartItem,
    showNotification,
    splitProductFromCart,
    advancedKeypadValue,
    orderItems,
    translate,
  ]);

  const onIncrementSeatNumber = useCallback(() => {
    if (order && order.table) {
      updateCart(OrderAction.ORDER_ASSIGN_TABLE, {
        tableId: order.table.id,
        tableName: order.table.name,
        guestCount: order.table.guestCount + 1,
      });
      setSelectedSeatNumberStr(String(order.table.guestCount + 1));
    }
  }, [order, updateCart]);

  const onSetSelectedSeatNumber = useCallback(seatNumber => {
    setSelectedSeatNumberStr(seatNumber);
  }, []);

  useEffect(() => {
    const item = orderItems.find(x => x.id === selectedCartItem?.item);
    if (selectedCartItem && isSeatManagementEnabled) {
      if (item?.seatNumber !== selectedSeatNumber) {
        updateCart(OrderAction.ORDER_UPDATE_SEAT_NUMBER, {
          seatNumber: selectedSeatNumber,
          orderItemIds: [selectedCartItem.item],
        });
        unselectCartItem();
      }
    }
  }, [
    selectedCartItem,
    selectedSeatNumber,
    isSeatManagementEnabled,
    unselectCartItem,
    updateCart,
    setSelectedSeatNumberStr,
    orderItems,
  ]);

  /*
  This will removed applied schedule adjustments if it won't satisfy the adjustment rule
  */
  const removeInvalidScheduleAdjustments = useCallback(
    (orderAdjustments?: Adjustment[], invalidAdjustmentIds?: string[]) => {
      if (!invalidAdjustmentIds?.length) return;
      const itemAdjustmentIds = order?.orderItems
        ?.map(
          item =>
            item.adjustments?.find(a =>
              invalidAdjustmentIds.includes(a.id as string),
            )?.id,
        )
        .filter(adjId => adjId) as string[];
      const adjustmentIdsToRemove = orderAdjustments
        ?.filter(a => invalidAdjustmentIds.includes(a.id as string))
        .map(a => a.id);
      if (adjustmentIdsToRemove?.length || itemAdjustmentIds?.length) {
        updateCart<RemoveOrderAdjustments>(
          OrderAction.ORDER_REMOVE_ADJUSTMENTS,
          {
            ...(adjustmentIdsToRemove?.length && {
              adjustmentIds: adjustmentIdsToRemove as string[],
            }),
            ...(itemAdjustmentIds?.length && {
              orderItemAdjustmentIds: itemAdjustmentIds as string[],
            }),
          },
        );
      }
    },
    [order?.orderItems, updateCart],
  );

  /*
    This will returns applied adjustments
  */
  const getAdjustmentsInfo = useCallback(() => {
    let adjustments: Adjustment[] = [];
    if (session.currentVenue?.adjustments?.length) {
      adjustments = session.currentVenue?.adjustments;
    }
    adjustments = adjustments.filter(
      a => !removedScheduleAdjustmentIdsRef.current?.includes(a.id as string),
    );
    if (appliedManualAdjustmentIdRef.current) {
      const matchedAdjustment = adjustments.find(
        a => a.id === appliedManualAdjustmentIdRef.current,
      );
      matchedAdjustment &&
        (adjustments = [{ ...matchedAdjustment, autoApply: false }]);
    }

    return {
      isAdjustmentsExist: !!adjustments?.length,
      adjustments: adjustments,
      appliedAdvanceDiscountIdsOnItems: appliedManualAdjustmentIdRef.current
        ? [appliedManualAdjustmentIdRef.current]
        : [],
    };
  }, [session.currentVenue?.adjustments]);

  /**
   * This function reset the appliedManualAdjustmentIdRef, this is going to used the find and get the advanced discounts for items
   */
  const resetManualAdjustmentId = useCallback(
    (adjustmentByOrderItem: AdjustmentByOrderItemMap) => {
      if (appliedManualAdjustmentIdRef.current) {
        const isRetriggered = Object.values(adjustmentByOrderItem || {}).find(
          item => item.splitQty || item.adjustment === undefined,
        );
        const isManualAdjustmentExecuted = Object.values(
          adjustmentByOrderItem || {},
        ).every(item => item.adjustment);
        isManualAdjustmentExecuted &&
          (selectedItemAdjustment.current =
            appliedManualAdjustmentIdRef.current);
        !isRetriggered && (appliedManualAdjustmentIdRef.current = '');
      }
    },
    [],
  );

  const scheduleAdjustments = useMemo(() => {
    const {
      isAdjustmentsExist,
      adjustments,
      appliedAdvanceDiscountIdsOnItems,
    } = getAdjustmentsInfo();
    if (
      (order?.subTotal || order?.orderItems?.length) &&
      (isAdjustmentsExist || localSurchargeList?.length) &&
      isDirty &&
      !selectedItemAdjustment.current
    ) {
      const scheduleAdjustments = getScheduleAdjustments(
        adjustments,
        order.orderItems,
        {
          selectedOrderType,
          guestCount: order?.table?.guestCount,
          storeId: currentStoreId,
          isManuallyTriggered: !!appliedManualAdjustmentIdRef.current,
          appliedAdvanceDiscountIdsOnItems,
        },
      );
      /**
       * Resetting the adjustment id, this is manual adjustment which we need to reset
       * getScheduleAdjustments function may execute multiple time for the same adjustment
       * Ex: Buy 6 items and get 10% off and we've 7 items cart so we need to split first and will apply discount
       */
      resetManualAdjustmentId(scheduleAdjustments.adjustmentByOrderItem);
      /*
      This will remove the adjustments if it is invalid due to the adjustment rule
      Ex: It would applied at Dine In order but won't apply for TakeAway
      */
      if (scheduleAdjustments.invalidAdjustmentIds?.length) {
        removeInvalidScheduleAdjustments(
          order?.adjustments ?? [],
          scheduleAdjustments.invalidAdjustmentIds,
        );
      }
      /*
      This will eliminate to add the schedule adjustment again
      If we've removed it from function map
      */
      const filteredAdjustments = excludeRemovedScheduleAdjustments(
        scheduleAdjustments.validAdjustments,
        scheduleAdjustments.adjustmentByOrderItem,
      );

      const validLocalSurchages = getValidSurcharges(localSurchargeList);
      const orderAdjustments =
        validLocalSurchages?.length > 0
          ? validLocalSurchages
          : filteredAdjustments.orderAdjustments;
      return {
        orderAdjustments,
        adjustmentByOrderItem: filteredAdjustments.adjustmentByOrderItem,
      };
    } else {
      selectedItemAdjustment.current && (selectedItemAdjustment.current = '');
      return { orderAdjustments: [], adjustmentByOrderItem: {} };
    }
  }, [
    order?.subTotal,
    order?.orderItems,
    order?.table?.guestCount,
    order?.adjustments,
    localSurchargeList,
    selectedOrderType,
    currentStoreId,
    excludeRemovedScheduleAdjustments,
    getValidSurcharges,
    removeInvalidScheduleAdjustments,
    getAdjustmentsInfo,
    resetManualAdjustmentId,
    isDirty,
  ]);

  const sortedAllPages = useMemo(
    () => sortBy(allNestedPages, 'name'),
    [allNestedPages],
  );

  const activeOrderItem = useMemo(() => {
    return ((orderItems || []).find(
      item => item.id === selectedCartItem?.item,
    ) || last(orderItems)) as OrderItem;
  }, [orderItems, selectedCartItem?.item]);

  const actionMap = useFunctionMaps(
    orderItems,
    setSelectedCartItem,
    selectedCartItem,
    escapeDiscardModal,
    advancedKeypadValue,
    pricingGroup,
    pricingGroupOptions,
    isOrderComplete,
    menus,
    setAdvancedKeypadValue,
    setPricingGroup,
    onPressUpdateOrderNotes,
    onPressTransferItems,
    allProducts,
    onPressNewOrder,
    onPressTableNumber,
    orderType,
    requestToSetTableOrder,
    unselectCartItem,
    areCartItemsValid,
    checkForUnfiredItems,
    isValidTotalPriceBeforeRemoveItem,
    navigateToPostSaleScreen,
    assignedCustomer,
    redeemRewards,
    loyaltySettings,
    rewardRules,
    earningRules,
    updateOrderName,
    courseOptions,
    handleSwitchCourseItem,
    sortedAllPages,
    onChangeProductQuantity,
    computeRestrictVoidItem,
    onAddVoucher,
    isAdvanceDiscountEnabled,
    {
      setRemovedScheduleAdjustmentIds,
      resetManualAdjustmentId,
      appliedManualAdjustmentIdRef,
    },
    isCartActionRestricted,
  ) as Action[];

  const handleAddProductToCart = useCallback(
    (selectedProduct: Product, isLongPress?: boolean) => {
      const isAllergic = isItemMarkedAsAllergic(selectedProduct.allergens);
      if (isLongPress) {
        viewProductInfoModal(selectedProduct);
      } else {
        isAllergic
          ? allergensWarningModal(selectedProduct)
          : addProductToCart(selectedProduct);
      }
    },
    [
      isItemMarkedAsAllergic,
      allergensWarningModal,
      addProductToCart,
      viewProductInfoModal,
    ],
  );

  const onPressCompleteOrder = useCallback(async () => {
    if (order?.payments?.length && order.amountDue === 0) {
      // if there are payments and no amount due we complete order
      updateCart<CompleteOrderEvent>(OrderAction.ORDER_COMPLETE);
      saveOrderInvocation();
    } else {
      // if there are no payments and no amount due then we complete by adding cash payment of 0
      const cashPayment = paymentTypes.find(
        x => x.name.toLowerCase() === 'cash',
      );
      if (cashPayment?.id) {
        updateCart<OrderPaymentEvent>(OrderAction.ORDER_PAYMENT, {
          tendered: 0,
          paymentTypeId: cashPayment.id,
          tip: 0,
          change: 0,
          roundOffDifference: 0,
          paymentTypeName: cashPayment.name,
        });
        saveOrderInvocation();
      }
    }
  }, [
    order?.amountDue,
    order?.payments?.length,
    paymentTypes,
    saveOrderInvocation,
    updateCart,
  ]);

  //parent callbacks
  useEffect(() => {
    if (onAssignCustomer) onAssignCustomer.current = onAssignCustomerToOrder;
    if (unAssignCustomer) unAssignCustomer.current = unassignCustomerToOrder;
    if (handleSelectProductFromSearch)
      handleSelectProductFromSearch.current = handleSelectProduct;
    if (newOrderButtonOnSaveCallback)
      newOrderButtonOnSaveCallback.current = newOrderButtonOnSaveDiscardModal;
    if (pressPrintReceiptCallback)
      pressPrintReceiptCallback.current = onPressPrintReceipt;
  }, [
    onAssignCustomer,
    handleSelectProduct,
    handleSelectProductFromSearch,
    newOrderButtonOnSaveCallback,
    newOrderButtonOnSaveDiscardModal,
    onAssignCustomerToOrder,
    onPressPrintReceipt,
    pressPrintReceiptCallback,
    unAssignCustomer,
    unassignCustomerToOrder,
  ]);

  useEffect(() => {
    if (
      order?.status === OrderStatus.CREATED &&
      order?.orderItems.length === 0
    ) {
      resetAppliedRef();
    }
  }, [order?.status, order?.orderItems.length, resetAppliedRef]);

  useEffect(() => {
    if (scheduleAdjustments.orderAdjustments?.length) {
      applyScheduleAdjustments(scheduleAdjustments.orderAdjustments);
    }
    if (Object.values(scheduleAdjustments.adjustmentByOrderItem || {})) {
      applyAdvancedDiscounts(scheduleAdjustments.adjustmentByOrderItem);
    }
  }, [applyScheduleAdjustments, applyAdvancedDiscounts, scheduleAdjustments]);

  const createOrUpdateModifierToCart = useCallback(
    (selectedOptionGroups: SelectedOptionGroup[]) => {
      const orderItemId = activeOrderItem?.id;
      const existingModifiers = (activeOrderItem?.modifiers ||
        []) as OrderItemModifier[];
      const existingModifierGroups = groupBy(
        existingModifiers,
        'modifierGroupId',
      );

      const allModifiers = selectedOptionGroups
        .map(optionGroup => {
          return optionGroup?.options;
        })
        .flat();

      const modifierMaps = keyBy(allModifiers, 'id');
      const selectedModifiers = selectedOptionGroups
        .map(optionGroup => {
          return optionGroup.options
            .map(option => {
              const orderItemModifiers: Partial<OrderItemModifier>[] = [];
              orderItemModifiers.push({
                //removeDefault quantity already handle in mapDefaultOptionItem()
                quantity: option?.quantity || 1,
                unitPrice: option?.removeDefault
                  ? 0
                  : getBestPriceOfModifier(option as Modifier),
                modifierGroupId: optionGroup.groupId,
                modifierGroupPriority: optionGroup?.priority,
                id: option.id,
                name: option?.removeDefault
                  ? generateDeselectDefaultOption(option?.name)
                  : option.name,
              });
              return orderItemModifiers;
            })
            .flat();
        })
        .flat() as OrderItemModifier[];

      const newOrUpdateModifiers = differenceWith(
        selectedModifiers.map(checkItemChange),
        existingModifiers.map(checkItemChange),
        isEqual,
      );

      const removeModifiers = differenceWith(
        existingModifiers.map(checkItemRemoved),
        selectedModifiers.map(checkItemRemoved),
        isEqual,
      );

      removeModifiers.forEach(mod => {
        updateCart<RemoveModifierEvent>(
          OrderAction.ORDER_ITEM_REMOVE_MODIFIER,
          {
            modifierId: mod.id as string,
            orderItemId,
            modifierGroupId: mod?.modifierGroupId as string,
            unitPrice: mod.unitPrice,
          },
        );
      });

      const newModifiers = newOrUpdateModifiers.filter(mod => {
        const existingModifiers =
          existingModifierGroups[mod.modifierGroupId as string] || [];
        return !existingModifiers.find(
          (existMod: OrderItemModifier) =>
            existMod.id === mod.id &&
            existMod.unitPrice === mod.unitPrice &&
            existMod.name === mod.name,
        );
      });

      const updateModifiers = differenceWith(
        newOrUpdateModifiers,
        newModifiers,
        isEqual,
      );

      newModifiers.forEach(mod => {
        const modId = mod.id as string;
        const modifier = modifierMaps[modId] as Modifier;
        updateCart<AddModifierEvent>(OrderAction.ORDER_ITEM_ADD_MODIFIER, {
          modifierId: modId,
          orderItemId,
          quantity: mod?.quantity,
          taxes: getTaxFromModifier(modifier),
          unitPrice: mod?.unitPrice,
          name: mod?.name,
          modifierGroupId: mod.modifierGroupId,
          modifierGroupPriority: mod.modifierGroupPriority,
          alternateNames: modifier?.alternateNames,
        });
      });

      updateModifiers.forEach(mod => {
        updateCart<UpdateModifierQuantityEvent>(
          OrderAction.ORDER_ITEM_UPDATE_MODIFIER_QUANTITY,
          {
            modifierId: mod.id as string,
            orderItemId,
            quantity: mod.quantity,
            modifierGroupId: mod.modifierGroupId as string,
            unitPrice: mod.unitPrice,
          },
        );
      });
    },
    [activeOrderItem?.id, activeOrderItem?.modifiers, updateCart],
  );

  const createOrUpdateComboProducts = useCallback(
    (comboItems: Array<Partial<OrderItem>>, orderItemId?: string) => {
      const { productMap, productPricing } = getComboProductPricing(comboItems);
      const selectedItem = orderItems.find(
        i => i.id === selectedCartItem?.item,
      );
      const selectedItemMap = keyBy(
        selectedItem?.comboItems || [],
        'product.id',
      );

      updateCart<AddComboEvent>(OrderAction.ORDER_ITEM_ADD_COMBO, {
        orderItemId: orderItemId || activeOrderItem?.id,
        comboItems: comboItems.map(comboItem => {
          const product = (comboItem.product || {}) as Product;
          const enrichedProduct = productMap[product.id];
          return {
            id: !orderItemId
              ? selectedItemMap[product.id]?.id || uuidV4()
              : uuidV4(),
            productId: product.id,
            productName: product.name,
            ...(enableCourses && {
              courseId: enrichedProduct.course?.id,
              courseName: enrichedProduct.course?.name,
            }),
            printerProfiles:
              enrichedProduct?.printerProfiles?.map(profile => profile.id) ||
              [],
            quantity: comboItem.quantity ?? 1,
            unitPrice: comboItem?.unitPrice ?? 0,
            fireItem: isItemReadyToFire(enrichedProduct.course?.id),
            productOriginalPrice:
              productPricing[product.id].sellingPrice.amount,
            modifierGroupId: comboItem.modifierGroupId ?? '',
            modifiers: comboItem.modifiers ?? [],
            note: comboItem.notes ?? '',
            ...(product.optionValues?.length && {
              optionValues: product.optionValues,
            }),
            ...(comboItem.variant && {
              variantId: comboItem.variant.id,
              variantName: comboItem.variant.name,
            }),
          };
        }),
      });
    },
    [
      activeOrderItem,
      updateCart,
      getComboProductPricing,
      enableCourses,
      selectedCartItem,
      orderItems,
      isItemReadyToFire,
    ],
  );

  const onUpdateOptionsToItem = useCallback(
    (
      selectedOptionGroups: SelectedOptionGroup[],
      comboItems?: Array<Partial<OrderItem>>,
    ) => {
      const orderItemId = activeOrderItem?.id;
      if (!orderItemId) return;

      if (selectedOptionGroups?.length) {
        createOrUpdateModifierToCart(selectedOptionGroups);
      } else {
        !comboItems?.length &&
          showNotification({
            error: true,
            message: translate('order.emptyComboItemMessage'),
          });
        createOrUpdateComboProducts(comboItems || [], '');
      }
    },
    [
      activeOrderItem,
      createOrUpdateComboProducts,
      createOrUpdateModifierToCart,
      showNotification,
      translate,
    ],
  );

  const filteredActions = useMemo(() => {
    const map = actionMap?.filter(
      action =>
        !action.feature ||
        (action.feature === FeatureIDs.LOYALTY // FIXME: handle show/hide Rewards function button for loyalty properly
          ? isLoyaltyEnabled
          : isFeatureEnabled(action.feature)),
    );
    if (!isTransferItemsEnabled) {
      return map.filter(
        ({ action }) => action !== FunctionMapActions.TRANSFER_ITEMS,
      );
    }
    return map;
  }, [actionMap, isFeatureEnabled, isLoyaltyEnabled, isTransferItemsEnabled]);

  const allergens = useMemo(() => {
    return order?.allergens?.[seatNumberForAllergens] ?? [];
  }, [order, seatNumberForAllergens]);

  return (
    <View style={[styles.container, { height: safeHeight }]}>
      <LoadingOverlay isLoading={loading} />
      <Cart
        order={order as Order}
        orderType={orderType as OrderType}
        courses={courses}
        onToggleAutoFire={handleToggleAutoFire}
        onPressChangeOrderType={onPressChangeOrderType}
        onIncrementSeatNumber={onIncrementSeatNumber}
        setSelectedSeatNumber={onSetSelectedSeatNumber}
        onPressTableNumber={onPressTableNumber}
        orderItems={orderItems}
        onSelectCartItem={onSelectCartItem}
        onDeselectCartItem={unselectCartItem}
        selectedCartItem={selectedCartItem}
        isAdvancedKeypad={isAdvancedKeypad}
        disableCartActions={disableCartActions}
        isOrderComplete={isOrderComplete}
        onPressDangerAction={onPressDangerAction}
        onPressUpdatePrice={onPressUpdatePrice}
        onPressUpdateQuantity={onPressUpdateQuantity}
        splitProductFromCart={splitProductFromCart}
        onPressAction={onPressAction}
        onPressSplitProductFromCart={onPressSplitProductFromCart}
        advancedKeypadValue={advancedKeypadValue}
        setAdvancedKeypadValue={setAdvancedKeypadValue}
        onPressPay={onPressPay}
        disableOrderActions={disableOrderActions}
        isDirty={isDirty}
        onPressNewOrder={onPressNewOrder}
        onPressSave={onPressSave}
        disableSaveAction={disableSaveAction}
        enableQuickPaymentMode={enableQuickPaymentMode}
        // navigateToRefundScreen={navigateToRefundScreen}
        onSwitchCourseItem={handleSwitchCourseItem}
        onPressCompleteOrder={onPressCompleteOrder}
        onSelectReward={onSelectRewardItem}
        selectedReward={selectedRewardItem}
        onShowAllergens={onShowAllergens}
        selectedSeatNumber={
          selectedSeatNumber ? String(selectedSeatNumber) : undefined
        }
        updateCart={updateCart}
      />
      <Catalog
        allProducts={allProducts}
        variantMaps={allVariants}
        addProductToCart={handleAddProductToCart}
        addVariantToProduct={addVariantProductToCart}
        actions={filteredActions}
        addModifierToProduct={addModifierToCart}
        removeItemFromCart={removeItemFromCart}
        unselectCartItem={unselectCartItem}
        replaceVariant={replaceVariantInTheCart}
        selectedItem={selectedCartItem}
        selectedVariantKey={selectedVariantKey}
        selectedModifiers={selectedModifiersOfSelectedProduct}
        orderId={orderId}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        allPageItemMaps={allPageItemMaps}
        sortedMenuItems={sortedMenuItems}
        onUpdateOptionsToItem={onUpdateOptionsToItem}
        activeOrderItem={activeOrderItem}
        allergens={allergens}
      />
      {isTransferItemsVisible && (
        <TransferPreview
          orders={order ? [order] : []}
          onClose={onCloseTransferItems}
          position="left"
          onSubmit={onSelectOrderItemsToTransfer}
        />
      )}
    </View>
  );
};

export default TakeOrderContainer;
