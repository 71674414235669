import { useReactiveVar } from '@apollo/client';
import { App } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { Tracer } from '@oolio-group/tracer-client';
import { useAppState } from '@react-native-community/hooks';
import {
  NavigationContainer,
  NavigationContainerRef,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { differenceInHours } from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AppStateStatus, Linking, Platform } from 'react-native';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, pluck } from 'rxjs/operators';
import OrderSyncEffect from './OrderSyncEffect';
import { AuthState } from './constants';
import { useNotification } from './hooks/Notification';
import { useSettings } from './hooks/app/useSettings';
import useBehaviorSubjectState from './hooks/app/useSubjectState';
import DeviceCodeLogin from './screens/Auth/DeviceCodeLogin/DeviceCodeLogin';
import LoginTypeSelection from './screens/Auth/Login/Login';
import BackOfficeNavigator from './screens/BackOffice/BackOfficeNavigator';
import LoadingScreen from './screens/Loading/Loading';
import UnsupportedVersionScreen from './screens/Loading/UnsupportedVersion';
import POSNavigator from './screens/POS/POSNavigator';
import { Session } from './state/Session';
import { failedPrintJobsCountVar, forceUpdateVersionVar } from './state/cache';
import { lastActiveTimeSubject } from './state/lastActiveTime';
import {
  deepLinkConfig,
  navigateToCallback,
  navigateToLockScreen,
  navigateToOnboardingScreen,
  redirectToSsoProvider,
} from './state/navigation';
import * as settings from './state/preferences';
import { getUserActivity, setUserActivity } from './state/preferences';
import { tokenUtility } from './state/tokenUtility';
import { userUtility } from './state/userUtility';
import * as storage from './storage/interface';
import { WorkerActionResult, WorkerActionResultStatus } from './workers/utils';
import { useFeatureFlags } from './hooks/app/useFeatureFlags';
import CallbackScreen from './screens/Auth/Callback/CallbackScreen';
import Onboard from './screens/Auth/Onboarding/OnboardScreen';
import { useNetworkStatus } from './hooks/app/useNetworkStatus';

const isNavigationToPosScreen = (url: string | null) => {
  return url && /^https?:\/\/[^\/]+\/pos/gi.test(url);
};
const isPortalOnboarding = (link: string | null) => {
  if (!link) return false;
  const url = new URL(link);
  const org = url.searchParams.get('orgId');
  const user = url.searchParams.get('user');
  if (org && user) return true;
  return false;
};
const isOnboardingReturn = (link: string | null) => {
  if (!link) return false;
  return link && /^https?:\/\/[^\/]+\/onboarding/gi.test(link);
};
const isCallbackingLogin = (url: string | null) => {
  return url && /^https?:\/\/[^\/]+\/callback/gi.test(url);
};

const Stack = createStackNavigator();

const Navigator: React.FC = () => {
  const [sessionStorage] = useSettings('session');
  const [initialURL, setInitialURL] = useState<string | null>(null);
  const currentAppState = useAppState();
  const previousAppState = useRef<AppStateStatus>();
  const [authState, setAuthState] = useState<AuthState>(AuthState.LOADING);
  const [isUserActRestored, finishRestoreUserAct] = useState<boolean>(false);
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const { setValue: setLastActiveTime } = useBehaviorSubjectState(
    lastActiveTimeSubject,
  );
  const navigationRef = useRef<NavigationContainerRef>(null);
  const forceUpdateVersion = useReactiveVar(forceUpdateVersionVar);
  const { isLoading: loadingNetworkStatus } = useNetworkStatus();

  const onWorkerMessage = useCallback(
    async (messages: WorkerActionResult[]) => {
      try {
        if (messages) {
          const newMessage = (messages || [])[messages?.length - 1 || 0];
          if (newMessage?.status === WorkerActionResultStatus.ERROR) {
            failedPrintJobsCountVar(
              (messages || []).reduce(
                (acc, item) =>
                  item.status === WorkerActionResultStatus.ERROR ? ++acc : acc,
                0,
              ),
            );
          } else {
            failedPrintJobsCountVar(0);
          }

          if (newMessage && !newMessage.processed) {
            showNotification({
              error: true,
              message: `${translate('printing.printFailed')} ${
                newMessage.message
              }`,
            });

            await storage.setItem(
              settings.WORKER_MESSAGES_KEY,
              messages.map(message =>
                message.requestId === newMessage.requestId
                  ? { ...message, processed: true }
                  : message,
              ),
            );

            Tracer.getInstance().captureException(
              new Error('Docket print Failed'),
              {
                extra: {
                  path: 'pos-app/src/Navigator.tsx',
                  printMessage: JSON.stringify(newMessage),
                },
              },
            );
          }
        }
      } catch {
        // ignore
      }
    },
    [showNotification, translate],
  );

  useEffect(() => {
    (async () => {
      const responses =
        (await storage.getItem<WorkerActionResult[]>(
          settings.WORKER_MESSAGES_KEY,
        )) || [];
      await storage.setItem<WorkerActionResult[]>(
        settings.WORKER_MESSAGES_KEY,
        responses.filter(job => {
          if (job.timestamp) {
            try {
              return (
                differenceInHours(new Date(), new Date(job.timestamp)) < 24
              );
            } catch {}
            return false;
          }
        }),
      );
    })();
  }, []);

  useEffect(() => {
    storage.addSubscription(settings.WORKER_MESSAGES_KEY, onWorkerMessage);
    return () => {
      storage.removeSubscription(settings.WORKER_MESSAGES_KEY, onWorkerMessage);
    };
  }, [onWorkerMessage]);

  useEffect(() => {
    async function getURL() {
      const initialURL = await Linking.getInitialURL();
      setInitialURL(initialURL);
    }
    getURL();
  }, []);

  useEffect(() => {
    if (previousAppState.current !== 'active' && currentAppState === 'active') {
      setLastActiveTime(Date.now());
    }
    previousAppState.current = currentAppState;
  }, [currentAppState, setLastActiveTime]);

  useEffect(() => {
    const subscription: Subscription = tokenUtility.getTokenInfo$
      .pipe(
        distinctUntilChanged((prev, curr) => prev.authState === curr.authState),
        pluck('authState'),
      )
      .subscribe(authState => {
        setAuthState(authState || AuthState.LOADING);
      });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [setAuthState]);

  const restoreUserActivity = useCallback(async () => {
    const activity = await getUserActivity();
    finishRestoreUserAct(true);
    if (activity) {
      userUtility.setUserActivity(activity);
    }
  }, []);

  useEffect(() => {
    restoreUserActivity();
  }, [restoreUserActivity]);

  useEffect(() => {
    const subscription: Subscription = userUtility.retrieveUserActivity$
      .pipe(filter(activity => Object.keys(activity.officeUsers).length > 0))
      .subscribe(activity => {
        if (activity) {
          setUserActivity(activity);
        }
      });
    return () => subscription.unsubscribe();
  }, []);

  useFeatureFlags();

  const linking = {
    prefixes: ['https://app.tillpos.co', 'till://'],
    config: deepLinkConfig,
  };

  const initialState = useMemo(() => {
    if (isPortalOnboarding(initialURL))
      return redirectToSsoProvider(initialURL as string);

    if (isOnboardingReturn(initialURL))
      return navigateToOnboardingScreen(initialURL as string);

    if (isCallbackingLogin(initialURL))
      return navigateToCallback(initialURL as string);
    if (
      (sessionStorage as Session)?.device &&
      (Platform.OS !== 'web' || isNavigationToPosScreen(initialURL))
    ) {
      return navigateToLockScreen(App.POS_APP, true);
    }
    return undefined;
  }, [initialURL, sessionStorage]);

  const onNavigationStateChange = useCallback(() => {
    const route = navigationRef.current?.getCurrentRoute();
    Tracer.getInstance().onNavigationStateChange({
      name: route?.name,
      params: route?.params,
    });
  }, []);

  if (forceUpdateVersion) {
    return <UnsupportedVersionScreen upgradeTo={forceUpdateVersion} />;
  }

  if (
    authState === AuthState.LOADING ||
    !isUserActRestored ||
    loadingNetworkStatus
  ) {
    return <LoadingScreen />;
  }

  return (
    <>
      <NavigationContainer
        linking={linking}
        initialState={initialState}
        fallback={<LoadingScreen />}
        ref={navigationRef}
        onStateChange={onNavigationStateChange}
      >
        <Stack.Navigator
          screenOptions={{ animationEnabled: true }}
          initialRouteName="BackOffice"
          headerMode="none"
        >
          <Stack.Screen
            component={LoginTypeSelection}
            name="LoginTypeSelection"
          />
          <Stack.Screen component={Onboard} name="onboarding" />
          <Stack.Screen component={CallbackScreen} name="callback" />
          <Stack.Screen component={DeviceCodeLogin} name="DeviceCodeLogin" />
          <Stack.Screen component={BackOfficeNavigator} name="BackOffice" />
          <Stack.Screen component={POSNavigator} name="POS" />
        </Stack.Navigator>
      </NavigationContainer>
      <OrderSyncEffect authState={authState} />
    </>
  );
};

export default Navigator;
