import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import {
  AdjustmentType,
  AssignCustomerEvent,
  Customer,
  LoyaltySettings,
  OrderAction,
  RewardAdjustment,
  RewardRule,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import {
  getFullFormattedPhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
  useCurrency,
  useTranslation,
} from '@oolio-group/localization';
import { getLoyaltyUnit, getOptionalProperty } from '@oolio-group/client-utils';
import { format } from 'date-fns';
import { isEmpty, isEqual, keyBy } from 'lodash';
import {
  useOolioLoyalty,
  mapRewardToLegacyReward,
} from '../../../hooks/useOolioLoyalty';
import {
  RewardMap,
  UseRewardsInterface,
} from '../../../hooks/orders/useRewards';
import {
  ProductNameFragment,
  useProducts,
} from '../../../hooks/app/products/useProducts';
import { useCart } from '../../../hooks/orders/useCart';
import { useNotification } from '../../../hooks/Notification';
import { useCustomers } from '../../../hooks/orders/useCustomers';
import theme from '../../../common/default-theme';
import styles from './CustomerRewardModal.styles';
import Icon from '../../Icon/Icon';
import RewardRow from './RewardRow/RewardRow';
import Gradient from '../../Gradient/Gradient';
import RedeemItemsModal from './RedeemItems/RedeemItemsModal';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import { EnrollmentSource } from '@oolio-group/loyalty-sdk';
import InputText from '../../Shared/Inputs/InputText';
import InputEmail from '../../Shared/Inputs/InputEmail';
import { DEFAULT_COUNTRY_CODE } from '../../../constants';
import { useSession } from '../../../hooks/app/useSession';
import InputPhone from '../../Shared/Inputs/InputPhone';
import { isValidEmail } from '../../../utils/validator';
import { useVariants } from '../../../hooks/app/variants/useVariants';
import { variantsFragment } from '../../../hooks/app/categories/graphql';

interface CustomerForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phoneCountry: string;
}

export interface CustomerRewardModalProps {
  customer?: Customer | undefined;
  enrolling?: boolean;
  loyaltySettings?: Partial<LoyaltySettings>;
  onDismiss?: () => void;
  onEnrol?: () => void;
  onRedeem: ReturnType<UseRewardsInterface>['redeemRewards'];
  rewardMap?: RewardMap;
  rewardRules: RewardRule[];
}

const CustomerRewardModal: React.FC<CustomerRewardModalProps> = props => {
  const {
    customer,
    loyaltySettings: legacyLoyaltySettings,
    rewardRules,
    onRedeem,
    onEnrol,
    enrolling,
    onDismiss,
    rewardMap,
  } = props;

  const { updateCart, order } = useCart();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const {
    updateCustomerInfo: updateCustomerCache,
    updateCustomer,
    enrollMemberLoyalty,
    error,
  } = useCustomers();

  const [session] = useSession();
  const currentStore = session.currentStore;

  const [showEnrolmentForm, setShowEnrolmentForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const phoneCountry =
    parsePhoneNumber(customer?.phone ?? '')?.countryCode ||
    customer?.preferredAddress?.isoCountryCode;

  const [form, setForm] = useState<CustomerForm>({
    firstName: customer?.firstName || '',
    lastName: customer?.lastName || '',
    email: customer?.email || '',
    phone: customer?.phone || '',
    phoneCountry: phoneCountry || DEFAULT_COUNTRY_CODE,
  });
  const { formatCurrency, unAppendCurrency, decimalSeparator } = useCurrency();
  const {
    isLoyaltyEnabled: isNewLoyaltyEnabled,
    loading,
    program,
    availableRewards: loyaltyRewards,
    getAvailableRewards,
    sendRewardSummary,
    error: loyaltyError,
  } = useOolioLoyalty({
    getProgram: true,
  });
  const { products, getAllProducts } = useProducts(
    undefined,
    ProductNameFragment,
  );

  const { variants, getAllVariants } = useVariants(undefined, variantsFragment);

  useEffect(() => {
    if (errorMessage) {
      showNotification({
        error: true,
        message: errorMessage,
      });
      setErrorMessage('');
    }
  }, [errorMessage, showNotification]);

  useEffect(() => {
    if (loyaltyError) {
      setErrorMessage(loyaltyError.message);
    }
  }, [loyaltyError]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const currentRewardMap = useRef<RewardMap>({});

  const [appliedRewardMap, setAppliedRewardMap] = useState<RewardMap>(
    rewardMap || {},
  );

  const allAdjustments = useMemo(() => {
    let allAdjustments: Array<{
      adjustment: RewardAdjustment;
      productId?: string;
    }> = [];
    // order level rewards
    allAdjustments = allAdjustments.concat(
      (
        (order?.adjustments || []).filter(
          adj => adj.adjustmentType == AdjustmentType.REWARD,
        ) as RewardAdjustment[]
      ).map(adjustment => ({ adjustment })),
    );
    // item level rewards
    order?.orderItems?.forEach(orderItem => {
      allAdjustments = allAdjustments.concat(
        (
          (orderItem.adjustments || []).filter(
            adj => adj.adjustmentType == AdjustmentType.REWARD,
          ) as RewardAdjustment[]
        ).map(adjustment => ({
          adjustment,
          productId:
            isNewLoyaltyEnabled && orderItem?.variant?.id
              ? orderItem?.variant?.id
              : orderItem.product.id,
        })),
      );
    });

    return allAdjustments;
  }, [order?.adjustments, order?.orderItems, isNewLoyaltyEnabled]);

  const availableBalance = useMemo(() => {
    return (
      loyaltyRewards?.availableBalance ?? customer?.loyaltyPointsBalance ?? 0
    );
  }, [customer?.loyaltyPointsBalance, loyaltyRewards?.availableBalance]);

  useEffect(() => {
    // Sync loyalty balance to cache if it is incorrect
    if (
      customer &&
      loyaltyRewards?.availableBalance &&
      customer.loyaltyPointsBalance !== loyaltyRewards.availableBalance
    ) {
      updateCustomerCache({
        ...customer,
        loyaltyPointsBalance: loyaltyRewards.availableBalance,
      });
    }
  }, [customer, loyaltyRewards?.availableBalance, updateCustomerCache]);

  const availableRewards = useMemo(() => {
    if (isNewLoyaltyEnabled && loyaltyRewards) {
      return loyaltyRewards.availableRewards.map(reward =>
        mapRewardToLegacyReward(reward, products, variants),
      );
    }
    // legacy rewards
    return rewardRules.filter(
      reward => reward.pointsRequired <= availableBalance,
    );
  }, [
    rewardRules,
    availableBalance,
    loyaltyRewards,
    isNewLoyaltyEnabled,
    products,
    variants,
  ]);

  const isPhoneValid = isValidPhoneNumber(
    getFullFormattedPhoneNumber(form.phoneCountry, form.phone),
  );

  const handleOnEnrol = useCallback(async () => {
    if (!isPhoneValid) {
      setErrorMessage(translate('customer.invalidPhoneMessage'));
      return;
    } else if (form.email && !isValidEmail(form.email)) {
      setErrorMessage(translate('customer.invalidEmailMessage'));
      return;
    }
    try {
      if (customer?.id) {
        const updatedCustomer = await updateCustomer({
          id: customer?.id,
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          phone: getFullFormattedPhoneNumber(form.phoneCountry, form.phone),
        });

        if (!updatedCustomer) return;

        const enrolledCustomer = await enrollMemberLoyalty(
          customer?.id,
          EnrollmentSource.POS,
        );
        if (enrolledCustomer) {
          updateCart<AssignCustomerEvent>(OrderAction.ORDER_ASSIGN_CUSTOMER, {
            customerId: enrolledCustomer.id,
            firstName: enrolledCustomer.firstName,
            phone: enrolledCustomer.phone,
            loyaltyMember: enrolledCustomer.loyaltyMember,
            isLoyaltyApplied: false, // exclude the legacy flag (this triggers the legacy worker, which is not what we want for new loyalty)
          });
          setShowEnrolmentForm(false);
          closeModal();
        }
      }
    } catch (error) {
      showNotification({
        error: true,
        message: (error as { message: string })?.message,
      });
    }
  }, [
    translate,
    form,
    customer?.id,
    enrollMemberLoyalty,
    isPhoneValid,
    showNotification,
    updateCustomer,
    closeModal,
    updateCart,
  ]);

  useEffect(() => {
    if (availableRewards.length && allAdjustments.length) {
      const rewardTypes = keyBy(availableRewards, 'id');
      const rewardMap = allAdjustments.reduce<RewardMap>((acc, curr) => {
        return {
          ...acc,
          [curr.adjustment.id]: [
            ...(acc[curr.adjustment.id] || []),
            {
              points: curr.adjustment.pointsRequired,
              type: rewardTypes[curr.adjustment.id]?.rewardType,
              productId: curr.productId,
              quantity: curr.adjustment.quantity,
              itemQuantity: curr.adjustment.itemQuantity,
            },
          ],
        } as RewardMap;
      }, {});
      currentRewardMap.current = rewardMap;
      // set initial map
      setAppliedRewardMap(current => {
        if (isEmpty(current)) {
          // set initial map if not provided
          return rewardMap;
        }
        return current;
      });
    }
  }, [allAdjustments, availableRewards]);

  useEffect(() => {
    if (isNewLoyaltyEnabled && customer) {
      getAvailableRewards(customer?.id, currentStore?.id);
      getAllProducts();
      getAllVariants();
    }
  }, [
    customer,
    isNewLoyaltyEnabled,
    getAvailableRewards,
    getAllProducts,
    getAllVariants,
    currentStore,
  ]);

  const remainingBalance = useMemo(
    () =>
      Object.keys(appliedRewardMap).reduce((acc, curr) => {
        const redemptions = appliedRewardMap[curr];
        const cost = redemptions.reduce(
          (acc, redemption) =>
            acc +
            redemption.points *
              (redemption.itemQuantity || redemption.quantity),
          0,
        );
        return acc - cost;
      }, availableBalance),
    [appliedRewardMap, availableBalance],
  );

  const onRewardChange = useCallback(
    (reward: RewardRule, quantity?: number) => {
      // checking if reward with same type applied
      const duplicated = Object.keys(appliedRewardMap).find(
        key =>
          key !== reward.id &&
          appliedRewardMap[key].some(
            redemption =>
              redemption.type === reward.rewardType && redemption.quantity > 0,
          ),
      );

      if (duplicated) {
        showNotification({
          message: translate('customerLoyalty.addDuplicatedReward'),
          error: true,
        });
        return;
      }

      if ('products' in reward) {
        showModal(
          <RedeemItemsModal
            onRedeem={props.onRedeem}
            currentRewardMap={currentRewardMap.current}
            allRewards={availableRewards}
            rewardMap={appliedRewardMap}
            reward={reward}
            remainingBalance={remainingBalance}
            onBack={rewardMap => {
              showModal(
                <CustomerRewardModal {...props} rewardMap={rewardMap} />,
              );
            }}
            orderItems={order?.orderItems}
            order={order}
          />,
        );
        return;
      }

      const newRewardMap: RewardMap = {
        ...appliedRewardMap,
        [reward.id]: [
          {
            quantity: quantity || 0,
            points: reward.pointsRequired,
            type: reward.rewardType,
          },
        ],
      };

      setAppliedRewardMap(newRewardMap);
    },
    [
      showNotification,
      appliedRewardMap,
      translate,
      order,
      props,
      showModal,
      remainingBalance,
      availableRewards,
    ],
  );

  const onPressClose = () => {
    onDismiss && onDismiss();
    closeModal();
  };

  const handleRedeem = () => {
    onRedeem(
      currentRewardMap.current,
      appliedRewardMap,
      availableRewards,
      order,
    );
  };

  const hasEnoughBalance = (reward: RewardRule): boolean =>
    remainingBalance >= reward.pointsRequired;

  const hasViableProducts = (reward: RewardRule) => {
    if ('products' in reward) {
      const viableProducts = order?.orderItems?.map(item =>
        isNewLoyaltyEnabled && item.variant?.id
          ? item.variant?.id
          : item.product.id,
      );
      return reward.products.some(product =>
        viableProducts?.includes(product.id),
      );
    }

    return true;
  };

  const getAdditionalInfo = (reward: RewardRule) => {
    return !hasViableProducts(reward)
      ? translate('customerLoyalty.noRedeemableProducts')
      : undefined;
  };

  const allowRedeem = useMemo(() => {
    if (!currentRewardMap.current) return false;

    return Object.keys(appliedRewardMap).some(key => {
      // if any of the key is added or modified, allow redeem
      return (
        (!currentRewardMap.current[key] &&
          appliedRewardMap[key]?.some(redemption => redemption.quantity > 0)) ||
        (currentRewardMap.current[key] &&
          !isEqual(appliedRewardMap[key], currentRewardMap.current[key]))
      );
    });
  }, [appliedRewardMap]);

  const formatBalance = useCallback(
    (value: number) => {
      const currencyString = formatCurrency(value);
      const withoutCurrency = unAppendCurrency(currencyString);
      const withoutDecimal = withoutCurrency.split(decimalSeparator)[0];
      return withoutDecimal.padStart(2, '0');
    },
    [formatCurrency, unAppendCurrency, decimalSeparator],
  );

  const countForQuantity = (rewardId: string): number => {
    return (
      appliedRewardMap[rewardId]?.reduce(
        (acc, redemption) =>
          (redemption.itemQuantity || redemption.quantity) + acc,
        0,
      ) || 0
    );
  };

  if (!isNewLoyaltyEnabled && isEmpty(legacyLoyaltySettings)) return <></>;

  const loyaltySettings = program || legacyLoyaltySettings!;

  const handleEnrol = () => {
    onEnrol && onEnrol();
  };

  const onPressEnrol = () => {
    // Determine if we can enrol based on old loyalty being followed or the customer having a phone number
    const canEnrol = !isNewLoyaltyEnabled || form.phone;

    // Determine if we need to show the modal to get the customer's phone number
    const shouldShowModal = !showEnrolmentForm;

    if (shouldShowModal && canEnrol) {
      // Enrol the customer directly
      handleEnrol();
      return;
    }

    if (showEnrolmentForm) {
      // If the enrolment modal is already open, perform the enrolment process
      handleOnEnrol();
      return;
    }

    if (shouldShowModal) {
      // Open the modal to get the customer's phone number
      setShowEnrolmentForm(true);
    }
  };

  const onChangeFormInput = (prop: string, value: string) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  };

  if (loading)
    return (
      <View style={styles.loading}>
        <ActivityIndicator size={30} color={theme.colors.primary} />
      </View>
    );

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.btnClose} onPress={onPressClose}>
        <Icon size={24} name="times" color={theme.colors.white} />
      </TouchableOpacity>
      <Gradient colors={['#3E82F0', '#7C4DFF']}>
        <View style={styles.header}>
          <View style={styles.textGroup}>
            <Text testID="balance" style={styles.title}>
              {availableBalance && remainingBalance != availableBalance
                ? translate('customerLoyalty.remainingOfTotal', {
                    remaining: formatBalance(remainingBalance),
                    total: formatBalance(availableBalance),
                  })
                : formatBalance(remainingBalance)}
            </Text>
            <Text style={styles.textH2}>
              {translate('customerLoyalty.pointsAvailable', {
                unit: getLoyaltyUnit(remainingBalance, loyaltySettings),
              })}
            </Text>
          </View>
          <View style={styles.textGroup}>
            <Text style={styles.textH3} testID="customer-code">
              {customer?.code}
            </Text>
            <Text
              testID="customer-name"
              style={styles.textH2}
            >{`${customer?.firstName} ${customer?.lastName}`}</Text>
            <Text style={styles.textH3}>
              {customer && !customer?.loyaltyMember
                ? translate('customerLoyalty.notEnrolled')
                : customer?.createdAt
                ? translate('customerLoyalty.sinceDate', {
                    date: format(customer.createdAt, 'MMM dd, yyyy'),
                  })
                : 'N/A'}
            </Text>
          </View>
        </View>
      </Gradient>
      <View style={styles.content}>
        {customer?.loyaltyMember ? (
          <View style={styles.rewardSection}>
            <ScrollView>
              {availableRewards?.length ? (
                <View style={styles.rewards}>
                  {availableRewards.map(reward => {
                    const multiSteps = getOptionalProperty(reward, 'products');
                    const disabled = multiSteps
                      ? !hasViableProducts(reward)
                      : !hasEnoughBalance(reward);

                    return (
                      <RewardRow
                        key={reward.id}
                        reward={reward}
                        loyaltySettings={loyaltySettings}
                        onChange={onRewardChange}
                        quantity={countForQuantity(reward.id)}
                        disabled={disabled}
                        multiSteps={multiSteps}
                        additionalInfo={getAdditionalInfo(reward)}
                      />
                    );
                  })}
                </View>
              ) : (
                <View style={styles.emptyView}>
                  <Text style={theme.tables.emptyText}>
                    {translate('customerLoyalty.noRewardAvailable')}
                  </Text>
                </View>
              )}
            </ScrollView>
            <View style={styles.btnContainer}>
              {isNewLoyaltyEnabled && (
                <TreatButton
                  testID="btn-sendSummary"
                  type="neutralLight"
                  height={50}
                  label={translate('customerLoyalty.sendSummary')}
                  onPress={() => sendRewardSummary(customer?.id)}
                  disabled={!customer.loyaltyMember}
                />
              )}
              <TreatButton
                testID="btn-redeem"
                type="positive"
                height={50}
                label={translate('customerLoyalty.redeemRewards')}
                onPress={handleRedeem}
                disabled={!allowRedeem}
              />
            </View>
          </View>
        ) : (
          <View>
            {!showEnrolmentForm ? (
              <>
                <View style={styles.emptyView}>
                  <Text style={theme.tables.emptyText}>
                    {translate('customerLoyalty.enrollToEarnAndRedeem')}
                  </Text>
                </View>
                <TreatButton
                  testID="btn-enrol"
                  type="positive"
                  height={50}
                  label={translate('customerLoyalty.enrollCustomer')}
                  onPress={onPressEnrol}
                  isLoading={enrolling}
                  containerStyle={styles.btnSubmit}
                />
              </>
            ) : (
              <View>
                <View style={theme.forms.row}>
                  <InputText
                    testID="input-first-name"
                    title={translate('form.firstName')}
                    value={form.firstName}
                    placeholder={'Jenny'}
                    onChangeText={onChangeFormInput.bind(null, 'firstName')}
                    containerStyle={theme.forms.inputHalf}
                  />
                  <InputText
                    testID="input-last-name"
                    title={translate('form.lastName')}
                    value={form.lastName}
                    placeholder={'Jenny'}
                    onChangeText={onChangeFormInput.bind(null, 'lastName')}
                    containerStyle={theme.forms.inputHalf}
                  />
                </View>
                <View style={theme.forms.row}>
                  <InputPhone
                    testID="input-phone"
                    title={translate('form.phoneNumber')}
                    value={form.phone}
                    onChangeText={onChangeFormInput.bind(null, 'phone')}
                    errorMessage={
                      !form.phone
                        ? translate('customer.invalidPhoneMessage')
                        : undefined
                    }
                    onPressCountry={onChangeFormInput.bind(
                      null,
                      'phoneCountry',
                    )}
                    defaultCountry={form.phoneCountry}
                    containerStyle={theme.forms.inputHalf}
                  />
                  <InputEmail
                    testID="input-email"
                    title={translate('common.emailAddress')}
                    value={form.email}
                    placeholder={translate('common.emailPlaceholder')}
                    onChangeText={onChangeFormInput.bind(null, 'email')}
                    containerStyle={theme.forms.inputHalf}
                  />
                </View>
                <TreatButton
                  testID="enroll-loyalty"
                  type="positive"
                  height={50}
                  label={translate('customerLoyalty.enrollCustomer')}
                  onPress={onPressEnrol}
                  isLoading={enrolling}
                  containerStyle={styles.btnSubmit}
                />
              </View>
            )}
          </View>
        )}
      </View>
    </View>
  );
};

export default CustomerRewardModal;
